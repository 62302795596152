// css
import "./Banner.css";
// images
import banner from "../../Images/banner.png";
import MainBtn from "../../Components/MainBtn/MainBtn.js";
import SecondBtn from "../../Components/SecondBtn/SecondBtn.js";
import mouse from "../../Images/mouse.png";
import { CompanyName } from "../../constants.js";
// react
import { Link } from "react-router-dom";
function Banner() {
  const handleScroll = () => {
    document.getElementById("info").scrollIntoView();
  };

  return (
    <>
      <div className="banner-web-container">
        <div className="container banner-web-container-inside">
          <div className="banner-web-image-container">
            <img src={banner} />
          </div>
          <div className="banner-web-title-container">
            <div className="title-1">
              <p className="c-white bold rtl">
                خدمات کسب و کارتان را به ما بسپارید
              </p>
            </div>
            <div className="title-2">
              <div className="c-white bold rtl d-flex f-30">
                خدمات حرفه ای
                <div className="c-main scroller">
                  <span>
                    طراحی سایت
                    <br />
                    طراحی گرافیک
                    <br />
                    طراحی اپلیکیشن
                    <br />
                    شبکه‌های اجتماعی
                    <br />
                    هاستینگ
                    <br />
                    زیر ساخت
                  </span>
                </div>
              </div>
            </div>
            <div className="title-text">
              <p className="c-white-70 rtl text-align-justify">
                برای ارتقای کسب و کار خود به چه چیزی نیاز دارید ؟
                بدون شک ، یک وب‌سایت حرفه‌ای ! شرکت عماد با افتخار این خدمت را به صورت حرفه‌ای به شما ارائه می‌دهد.
                اما این تنها بخشی از خدمات ماست . ما همچنین خدمات هاستینگ ، طراحی اپلیکیشن موبایل ، مدیریت شبکه‌های اجتماعی و بسیاری خدمات دیگر را نیز در لیست گسترده خدمات خود داریم . با ما به سوی موفقیت گام بردارید.
              </p>
            </div>
            <div className="link-web-container">
              <a className="link1 pointer" href="#info">
                <MainBtn text="بیشتر بدانید" />
              </a>
              <div className="l-btn"></div>
              <Link className="link1 pointer" to="/service">
                <SecondBtn text="خدمات و سرویس ها" />
              </Link>
            </div>
          </div>
        </div>
        <div className="mouse-web-container">
          <img className="mb-10 pointer" src={mouse} onClick={handleScroll} />
          <p className="c-white-20 default">اسکرول کنید</p>
        </div>
      </div>

      <div className="banner-mob-container">
        <div className="d-flex flex-column justify-content-start justify-content-md-between align-items-center w-100">
          <div className="banner-mob-image-container d-flex justify-content-center">
            <img src={banner} />
          </div>
          <div className="w-100 banner-mob-title-container">
            <div className="title-1">
              <p className="c-white bold rtl">
                خدمات کسب و کارتان را به ما بسپارید
              </p>
            </div>
            <div className="title-2">
              <p className="c-white bold rtl">
                خدمات حرفه ای<span className="c-main"> طراحی سایت </span>
              </p>
            </div>
            <div className="title-text">
              <p className="c-white-70 rtl text-align-justify">
              برای ارتقای کسب و کار خود به چه چیزی نیاز دارید ؟
                بدون شک ، یک وب‌سایت حرفه‌ای ! شرکت عماد با افتخار این خدمت را به صورت حرفه‌ای به شما ارائه می‌دهد.
                اما این تنها بخشی از خدمات ماست . ما همچنین خدمات هاستینگ ، طراحی اپلیکیشن موبایل ، مدیریت شبکه‌های اجتماعی و بسیاری خدمات دیگر را نیز در لیست گسترده خدمات خود داریم .�با شرکت عماد، به سوی موفقیت گام بردارید.
              </p>
            </div>
            <div className="link-mob-container">
              <a className="link1 pointer" href="#info">
                <MainBtn text="بیشتر بدانید" />
              </a>
              <Link className="link1 pointer" to="/service">
                <SecondBtn text="خدمات و سرویس ها" />
              </Link>
            </div>
          </div>
        </div>
        <div className="mouse-mob-banner-container">
          <img className="mb-10" src={mouse} onClick={handleScroll} />
          <p className="c-white-20 default">اسکرول کنید</p>
        </div>
      </div>
    </>
  );
}

export default Banner;
