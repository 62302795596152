import "./GraphicPen.css";
import pen from "../../Images/whitepen.png";

function GraphicPen(props) {
  const { title, caption } = props;
  return (
    <div className="graphic-pen-container">
      <div className="card-graphic">
        <div className="d-flex justify-content-end">
          <div className="pen-container d-flex justify-content-center align-items-center">
            <img className="pen-icon" src={pen} alt="" />
          </div>
        </div>
        <div className="d-flex rtl txt-bold c-white">
          <p className="bold">{title}</p>
        </div>
        <div className="d-flex rtl txt-prop c-white-50">
          <p>{caption}</p>
        </div>
      </div>
    </div>
  );
}

export default GraphicPen;
