import './Pagination.css';
import ReactPaginate from 'react-paginate';
import left from '../../Images/left.png';
import right from '../../Images/right.png';

function Pagination({ pageCount, onPageChange }) {
    return (
        <ReactPaginate
            previousLabel={<img src={left} alt="Previous" />}
            nextLabel={<img src={right} alt="Next" />}
            breakLabel={'...'}
            breakClassName={'page-btn pointer'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={'w-100 d-flex pagination'}
            pageClassName={'page-btn pointer'}
            previousClassName={'page-btn pointer'}
            nextClassName={'page-btn pointer'}
            activeClassName={'page-btn-active'}
        />
    );
}

export default Pagination;
