import "./BannerInsta.css";
import bannerInsta from "../../Images/bannerInsta.png";
import MainBtn from "../../Components/MainBtn/MainBtn.js";
import SecondBtn from "../../Components/SecondBtn/SecondBtn.js";
import mouse from "../../Images/mouse.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function BannerInsta() {
  const handleScroll = () => {
    document.getElementById("insta").scrollIntoView();
  };
  return (
    <div className="container-fluid banner-container d-flex align-items-start">
      <div className="xl-mb-0  d-flex flex-column justify-content-start align-items-center w-100 banner-container-inside">
        <div className="banner-insta-image-container ">
          <img src={bannerInsta} />
        </div>
        <div className="w-100 banner-insta-title-container d-flex flex-column align-items-center">
          <div className="w-100 title-1">
            <p className="w-100 text-align-center c-white bold rtl">
              خدمات حرفه ای شبکه های اجتماعی
            </p>
          </div>
          <div className="w-100 title-2">
            <p className="w-100 text-align-center c-white rtl mb-30">
              <span className="c-main">
                خدمات حرفه ای اینستاگرام، تلگرام، توییتر، کلاب هاوس
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="mouse-web-container d-flex flex-column align-items-center">
        <img className="mb-10 pointer" src={mouse} onClick={handleScroll} />
        <p className="c-white-20 default">اسکرول کنید</p>
      </div>
    </div>
  );
}

export default BannerInsta;
