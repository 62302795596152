// css
import './MobMenu.css';

// images
import menubar from '../../Images/menubar.png';
import logo from '../../Images/logo.png';
import display from '../../Images/display.png';
import brush from '../../Images/brush.png';
import conection from '../../Images/conection.png';
import iPhone from '../../Images/iPhone.png';
import cash from '../../Images/cash.png';
import modem from '../../Images/modem.png';
import shop from '../../Images/shop.png';
import mobilehome from '../../Images/mobilehome.png';
import mobileuser from '../../Images/mobileuser.png';

// react
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';

function MobMenu(props){
    const closeNavbar = () => {
        props.onChange();
    }

    return (
        <div id="menubar">
            <div className="mobile-view-container">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="pointer" onClick={closeNavbar}>
                        <img className="mobile-menubar-icon" src={menubar} alt="" />
                    </div>
                    <div>
                        <Link className='link1' to="/">
                            <img className="mobile-logo-icon" src={logo} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="flex-fill d-flex flex-column justify-content-evenly">
                    <div className="d-flex mt-20">
                        <Link className='link1 w-100 h-100 mr-25' to="/design">
                            <div className="mobile-menu-items">
                                <img src={brush} alt="" />
                                <p className="c-white f-16 w-100 d-flex justify-content-center">طراحی گرافیک</p>
                            </div>
                        </Link>
                        <Link className='link1 w-100 h-100' to="/web-design">
                            <div className="mobile-menu-items">
                                <img src={display} alt="" />
                                <p className="c-white f-16 w-100 d-flex justify-content-center">طراحی سایت</p>
                            </div>
                        </Link>
                    </div>
                    <div className="d-flex mt-20">
                        <Link className='link1 w-100 h-100 mr-25' to="/social">
                            <div className="mobile-menu-items">
                                <img src={conection} alt="" />
                                <p className="c-white f-16 w-100 d-flex justify-content-center">شبکه های اجتماعی</p>
                            </div>
                        </Link>
                        <Link className='link1 w-100 h-100 ' to="/application">
                            <div className="mobile-menu-items">
                                <img src={iPhone} alt="" />
                                <p className="c-white f-16 w-100 d-flex justify-content-center">طراحی اپلیکیشن</p>
                            </div>
                        </Link>
                    </div>
                    <div className="d-flex mt-20 mb-20">
                        <Link className='link1 w-100 h-100 mr-25' to="/contact">
                            <div className="mobile-menu-items">
                                <img src={cash} alt="" />
                                <p className="c-white f-16 w-100 d-flex justify-content-center">پشتیبانی</p>
                            </div>
                        </Link>
                        <Link className='link1 w-100 h-100 ' to="/hosting">
                            <div className="mobile-menu-items">
                                <img src={modem} alt="" />
                                <p className="c-white f-16 w-100 d-flex justify-content-center">هاستینگ و سرور</p>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="mobile-view-footer">
                    <div className="mobile-footer-icon1 mt-20">
                        <Link className='link1' to="/"><img src={shop} alt="" /></Link>
                    </div>
                    <div className="mobile-footer-icon2 mt-20">
                        <Link className='link1' to="/"><img src={mobilehome} alt="" /></Link>
                    </div>
                    <div className="mobile-footer-icon3 mt-20">
                        <Link className='link1' to="/login"><img src={mobileuser} alt="" /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobMenu;