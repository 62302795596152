import "./ViewCall.css";
import calling from "../../Images/calling.png";

function ViewCall() {
  return (
    <div className="view-call-container">
      <div className="sec-graphic">
        <div className="sec-head d-flex flex-row">
          <div className="sec-card1 justify-content-center align-items-center d-flex flex-column">
            <div className="call-container d-flex justify-content-center align-items-center">
              <div>
                <img className="call-icon" src={calling} alt="" />
              </div>
            </div>
            <div className="mtn-head c-white">
              <p>طراحی متناسب با برند شما</p>
            </div>
            <div className="mtn-foot c-white-70">
              <p>
                تفاوتی ندارد چهره برند شما در چه حد جدی باشد. کسب و کارتان مربوط
                به یک نمایشگاه اتوموبیل باشد و یا کسب و کار مرتبط به بازی و
                سرگرمی داشته باشید. ما میتوانیم انواع طرح های گرافیکی برای انواع
                برند طراحی کنیم.
              </p>
            </div>
          </div>
          <div className="sec-card2 justify-content-center d-flex  align-items-center flex-column">
            <div className="call-container d-flex justify-content-center align-items-center">
              <div>
                <img className="call-icon" src={calling} alt="" />
              </div>
            </div>
            <div className="mtn-head c-white">
              <p>جذابیت بالای طراحی‌ها</p>
            </div>
            <div className="mtn-foot c-white-70">
              <p>
                یک لوگو و یا تیزر، بدون جذابیت تاثیری بر روی
                مخاطب ندارد. میتوانید از ما انتظار یک طرح ساده و در عین حال جذاب
                داشته باشید.
              </p>
            </div>
          </div>
        </div>
        <div className="sec-card3 w-100 d-flex flex-row-reverse justify-content-center align-items-center">
          <div className="d-flex justify-content-end align-items-center">
            <div className="call-container2 d-flex justify-content-center align-items-center">
              <div>
                <img className="call-icon" src={calling} alt="" />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column rtl">
            <div className="txt-head c-white">
              <p>سرعت عمل بالا</p>
            </div>
            <div className=" c-white-70">
              <p>
                صبر کردن کار سختی است. از این سو ما قصد منتظر گذاشتن شما عزیزان
                را نداریم. در کمترین زمان ممکن، طرح های مورد نیازتان را تحویل
                میدهیم.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCall;
