// css
import "./PageOne.css";
// State and Effect
import { useState, useEffect } from "react";
// Sections
import Header from "../../sections/Header/Header.js";
import Banner from "../../sections/Banner/Banner.js";
import Information from "../../sections/Information/Information.js";
import Factors from "../../sections/Factors/Factors.js";
import Features from "../../sections/Features/Features.js";
import Post from "../../sections/Post/Post.js";
import Improve from "../../sections/Improve/Improve.js";
import MainForm from "../../sections/MainForm/MainForm.js";
import Footer from "../../sections/Footer/Footer.js";
import PostWeb from "../../sections/PostWeb/PostWeb.js";
// Helmet
import { Helmet } from "react-helmet";
// Importing the constant
import { CompanyName } from "../../constants.js";

function PageOne() {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="page-one-container">
      <Helmet>
        <title>تیم عماد | Emad Team</title>
        <meta name="description" content={`${CompanyName} انجام کلیه امور تحت وب از جمله طراحی سایت ، هاستینگ و دامنه ، طراحی اختصاصی گرافیک ، ساخت برنامه موبایل`} />
        <meta name="keywords" content="طراحی سایت,هاست,دامنه,هاست و دامنه" />
      </Helmet>
      <Header />
      <div className="space-2-2"></div>
      <Banner />
      <div className="space-2"></div>
      <div id="info">
        <Information />
      </div>
      <div className="space-7"></div>
      <Factors />
      <div className="space-7"></div>
      <Features />
      <div className="space-3"></div>
      <div className="xs-d-none l-d-flex">
        <PostWeb />
      </div>
      <div className="w-100 xs-d-flex l-d-none">
        <Post til="pad" />
      </div>
      <div className="space-4"></div>
      <Improve />
      <div className="space-5"></div>
      <div className="container">
        <MainForm />
      </div>
      <div className="space-6"></div>
      <Footer />
    </div>
  );
}

export default PageOne;
