import "./Features.css";
import FeaturesCard from "../../Components/FeaturesCard/FeaturesCard.js";
import pen from "../../Images/pen.png";
import MainBtn from "../../Components/MainBtn/MainBtn.js";
import SecondBtn from "../../Components/SecondBtn/SecondBtn.js";

function Features() {
  return (
    <div className="container-lg d-flex flex-column-reverse flex-lg-row justify-content-center justify-content-lg-between align-items-end align-items-lg-center features-container">
      <div className="w-100 d-flex features-btn-container-sm justify-content-between justify-content-sm-end">
        <div className="features-btn-1">
          <a className="link1 pointer" href="/service">
                <MainBtn text="خدمات تیم ما" />
          </a>
        </div>
        <div className="features-btn-2">
          <a className="link1 pointer" href="/about">
            <SecondBtn text="بیشتر بدانید" />
          </a>
        </div>
      </div>
      <div className="d-flex features-cards h-100 align-items-center">
        <div className="d-flex flex-row flex-lg-column features-card-col-1 justify-content-between">
          <div className="features-card-item">
            <FeaturesCard
              title="سرعت عمل بالا"
              caption="به اتمام رساندن خدمات در کوتاه‌ترین زمان ممکن"
            />
          </div>
          <div className="features-card-item">
            <FeaturesCard
              title="سال ها سابقه"
              caption="سال ها سابقه در زمینه طراحی وبسایت ، هاستینگ ، طراحی و..."
            />
          </div>
        </div>
        <div className="d-flex flex-row flex-lg-column features-card-col-2 justify-content-between">
          <div className="features-card-item">
            <FeaturesCard title="مشاوره تخصصی" caption="مشاوره حرفه ای به شما در مورد گرفتن بهترین نتیجه" />
          </div>
          <div className="features-card-item">
            <FeaturesCard title="هزینه عادلانه" caption="هزینه منصفانه در قبال بهترین کیفیت" />
          </div>
        </div>
      </div>
      <div>
        <div className="features-content">
          <div className="d-flex justify-content-end align-items-md-center">
            <div className="d-flex flex-column rtl features-header c-white">
              <p>چرا تیم عماد؟</p>
              <p>? Why Emad Compony</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img className="pen-icon" src={pen} alt="Pen icon" />
            </div>
          </div>
          <div className="features-text rtl c-white-70">
            <p className="text-align-justify">
              چون ما برای شما ارزش قائلیم . ما در شرکت عماد همواره نهایت تلاش خود را میکنیم که شما از خدمات و عملکرد ما و تنیجه
              کار راضی باشید . ما با سال ها تجربه در فضای دیجیتال میتوانیم یکی از
              بهترین انتخاب های شما باشیم.
            </p>
          </div>
          <div className="d-flex features-btn-container-md justify-content-center justify-content-lg-end">
            <div className="features-btn-1">
              <a className="link1 pointer" href="/service">
                <MainBtn text="خدمات تیم ما" />
              </a>
            </div>
            <div className="features-btn-2">
              <a className="link1 pointer" href="/about">
                <SecondBtn text="بیشتر بدانید" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
