import './AboutUsLine.css';
import about from '../../Images/about.png';
import line from '../../Images/line.png';

function AboutUsLine(){
    return (
        <div className='about-us-line d-flex justify-content-center align-items-center' style={{backgroundImage: 'url('+line+')'}}>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column align-items-center'>
                    <div>
                        <p className='c-white bold fa'>درباره ما</p>
                    </div>
                    <div>
                        <p className='c-white en'>About US</p>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <img src={about} />
                </div>
            </div>
        </div>
    );
}

export default AboutUsLine;