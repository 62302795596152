import './SignUp.css';
import logo from '../../Images/logo.png';
import login from '../../Images/login.png'
import circle from '../../Images/circle.png';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import HeaderSign from '../../sections/HeaderSign/HeaderSign';
import FooterMob from '../../sections/FooterMob/FooterMob';
import email from '../../Images/emailfrom.png';
import key from '../../Images/key.png';
import { Helmet } from 'react-helmet';

function SignUp() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <>
            <Helmet>
                <title>ثبت نام - تیم عماد</title>
                <meta name="description" content="صفحه ثبت نام تیم عماد" />
                <meta name="keywords" content="تیم عماد, ثبت نام, حساب کاربری" />
            </Helmet>
            <div className='xs-d-none l-d-block signup-bg' style={{ backgroundImage: 'url(' + circle + ')' }}>
                <div className='container'>
                    <div className="signup-container flex-column">
                        <div className="signup-header w-100 d-flex justify-content-center align-items-center">
                            <Link className='link' to="/"><img className="pointer" src={logo} /></Link>
                        </div>
                        <div className="signup-main d-flex justify-content-center align-items-center w-100 h-100">
                            <div className="signup-card d-flex flex-column align-items-center">
                                <div className="signup-card-logo w-100 d-flex justify-content-center align-items-center">
                                    <img src={login} />
                                </div>
                                <div className="signup-card-header w-100 d-flex justify-content-center align-items-center">
                                    <p className="c-white bold">ورود به حساب کاربری</p>
                                </div>
                                <div className="signup-card-box w-100 d-flex">
                                    <input type="text" placeholder=" آدرس ایمیل" className="signup-input display-block rtl w-100" />
                                    <span className='login-mob-form-input-icon'><img src={email} /></span>
                                </div>
                                <div className="signup-card-box w-100 d-flex">
                                    <input type="password" placeholder="رمز عبور" className="signup-input display-block rtl w-100" />
                                    <span className='login-mob-form-input-icon'><img src={key} /></span>
                                </div>
                                <div className="signup-card-box w-100 d-flex">
                                    <input type="password" placeholder="تکرار رمز عبور" className="signup-input display-block rtl w-100" />
                                    <span className='login-mob-form-input-icon'><img src={key} /></span>
                                </div>
                                <div className="signup-card-btn w-100">
                                    <a href="https://dsh.emad.team/register.php" target='_blank'><button className="bg-main w-100 c-white">ایجاد حساب کاربری</button></a>
                                </div>
                                <div className="signup-card-footer w-100">
                                    <p className="c-white">حساب کاربری دارید؟<Link to="/login" className="link pointer c-main signup-link"> ورود به حساب </Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='xs-d-block l-d-none'>
                <div className='header-sign'>
                    <HeaderSign />
                </div>
                <div className='sign-log container mb-66'>
                    <div className="login-card-header mb-22-i w-100 d-flex justify-content-center align-items-center">
                        <p className="c-white bold">ورود به حساب کاربری</p>
                    </div>
                    <div className="login-card-box w-100 d-flex">
                        <input type="text" placeholder=" آدرس ایمیل" className="login-input display-block rtl w-100" />
                        <span className='login-mob-form-input-icon'><img src={email} /></span>
                    </div>
                    <div className="login-card-box w-100 d-flex">
                        <input type="password" placeholder="رمز عبور" className="login-input display-block rtl w-100" />
                        <span className='login-mob-form-input-icon'><img src={key} /></span>
                    </div>
                    <div className="login-card-btn w-100">
                        <Link to="/hosting-plan/" className='link1'><button className="bg-main w-100 c-white">ورود به حساب</button></Link>
                    </div>
                </div>
                <div className='sign-log container'>
                    <div className="login-card-header mb-22-i w-100 d-flex justify-content-center align-items-center">
                        <p className="c-white bold">ورود به حساب کاربری</p>
                    </div>
                    <div className="login-card-box w-100 d-flex">
                        <input type="text" placeholder=" آدرس ایمیل" className="login-input display-block rtl w-100" />
                        <span className='login-mob-form-input-icon'><img src={email} /></span>
                    </div>
                    <div className="login-card-box w-100 d-flex">
                        <input type="password" placeholder="رمز عبور" className="login-input display-block rtl w-100" />
                        <span className='login-mob-form-input-icon'><img src={key} /></span>
                    </div>
                    <div className="login-card-box w-100 d-flex">
                        <input type="password" placeholder="تکرار رمز عبور" className="login-input display-block rtl w-100" />
                        <span className='login-mob-form-input-icon'><img src={key} /></span>
                    </div>
                    <div className="login-card-btn w-100">
                        <a href="https://dsh.emad.team/register.php" target='_blank'><button className="bg-main w-100 c-white">ایجاد حساب کاربری</button></a>
                    </div>
                </div>
                <div>
                    <FooterMob />
                </div>
            </div>
        </>
    );
}

export default SignUp;
