import '../FAQSCard.css';
import faqs from '../../../Images/faqs.png';

function FAQSCard(){
    return (
        <>
        <div className='xs-d-none l-d-flex faqs-card-container flex-row-reverse'>
            <div className='faqs-card-image d-flex justify-content-center align-items-center'>
                <img src={faqs} />
            </div>
            <div className='h-100 d-flex flex-column justify-content-center'>
                <p className='faqs-card-text-1 c-white bold rtl '>از &nbsp; ۸ صبح &nbsp; الی  &nbsp; ۸ شب</p>
                <p className='faqs-card-text-2 c-white-80 rtl'>ساعات اداری مجموعه</p>
            </div>
        </div>

        <div className='xs-d-flex l-d-none faqs-card-container flex-column justify-content-center align-items-center'>
            <div className='xb-1 faqs-card-image d-flex justify-content-center align-items-center'>
                <img src={faqs} />
            </div>
            <div className=' d-flex flex-column justify-content-center'>
                <p className='xb-2 faqs-card-text-1 c-white bold rtl text-align-center '>راهنمای سفارش</p>
                <p className='faqs-card-text-2 c-white-80 rtl'>چطوری سفارش ثبت کنید</p>
            </div>
        </div>
        
        </>
    );
}

export default FAQSCard;