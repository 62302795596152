import './HeaderBlog.css'
import blogmain from '../../Images/blogmain.png';
import leftarrow from '../../Images/leftarrow.png';
import {Link} from 'react-router-dom';

function HeaderBlog () {
    return (
        <div className='container header-contact-blog d-flex justify-content-between align-items-center'>
            <div className='header-contact-left'>
                <Link className='link1' to="/"><img src={leftarrow} /></Link>
            </div>
            <div className='d-flex'>
                <p className='header-contact-text'>بلاگ</p>
                <img className='d-block header-contact-icon' src={blogmain} />
            </div>
        </div>
    );
}

export default HeaderBlog;