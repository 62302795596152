import "./FeaturesCard.css";
import features from "../../Images/features.png";

function Features(props) {
  const { title, caption } = props;
  return (
    <div className="features-card-container d-flex flex-column align-items-end">
      <div className="w-100 d-flex justify-content-start">
        <div className="features-card-icon d-flex justify-content-center align-items-center bg-main">
          <img src={features} alt="Features card icon" />
        </div>
      </div>
      <div className="w-100 features-card-header rtl">
        <p className="bold c-white">{title}</p>
      </div>
      <div className="w-100 features-card-content rtl">
        <p className="c-white">{caption}</p>
      </div>
    </div>
  );
}

export default Features;
