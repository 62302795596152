// css
import './Header.css';

// react
import {Link} from "react-router-dom";
import { useState, useEffect } from 'react';
// images
import accountuser from '../../Images/accountuser.png';
import menubaropen from '../../Images/menubaropen.png';
// components
import AdminBtn from '../../Components/AdminBtn/AdminBtn.js';
import Logo from '../../Components/Logo/Logo.js';
import Navbar from '../../Components/Navbar/Navbar.js';
// sections
import MobMenu from '../MobMenu/MobMenu';

function Header() {
    
    // states 
    const [openNav, setOpenNav] = useState(false);

    // functions
    const openNavbar = () => {
        if(!openNav){
            setOpenNav(true);
        }else{
            setOpenNav(false);
        }
        
    }

    // effect
    useEffect(() => {
        if(openNav){
            document.getElementById('menubar-container').classList.add('openNav');
            document.getElementById('menubar-container').classList.remove('closeNav');
        }else{
            document.getElementById('menubar-container').classList.add('closeNav');
            document.getElementById('menubar-container').classList.remove('openNav');
        }
    },[openNav]);

    // main
    return (
        <>
            <div className='container-lg header-web-container'>
                <div>
                    <Link className='link1' to="/Login">
                        <AdminBtn />
                    </Link>
                </div>
                <div  className='header-navbar-container'>
                    <Navbar />
                </div>
                <div>
                    <Link className='link1' to='/'><Logo /></Link>
                </div>
            </div>

            <div className="header-mob-container">
                <div className='pointer'>
                    <Link className='link1' to="/login"><img className="accountuser-icon" src={accountuser} alt="header icon"/></Link>
                </div>
                <div>
                    <Link className="link1" to="/"><Logo /></Link>
                </div>
                <div className='pointer' onClick={openNavbar}>
                    <img className="menubar-open-icon" src={menubaropen} alt="menu bar icon"/>
                </div>
            </div>

            <div id="menubar-container">
                <MobMenu onChange={openNavbar} />
            </div>
        </>
    );
}

export default Header;