import "./Application.css";

import comput from "../../Images/comput.png";
import computer from "../../Images/computer.png";
import factorsWhite from "../../Images/factorswhite.png";
import headphone from "../../Images/headphone.png";
import pen from "../../Images/pen.png";
import ar from "../../Images/ar.png";
import al from "../../Images/al.png";

import MainBtn from "../../Components/MainBtn/MainBtn";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen";
import SecondBtn from "../../Components/SecondBtn/SecondBtn";
import ScrollOne from "../../sections/ScrollOne/ScrollOne";
import Improve from "../../sections/Improve/Improve";
import MainForm from "../../sections/MainForm/MainForm";
import Footer from "../../sections/Footer/Footer";
import Header from "../../sections/Header/Header";
import bannerApp from "../../Images/bannerApp.png";
import HeaderMob from "../../sections/HeaderMob/HeaderMob";
import BannerApp from "../../sections/BannerApp/BannerApp";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Application() {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <title>تیم عماد | خدمات طراحی و توسعه اپلیکیشن</title>
        <meta name="description" content="صفحه خدمات طراحی و توسعه اپلیکیشن تیم عماد. ارائه دهنده خدمات طراحی اپلیکیشن اندروید و iOS، مشاوره رایگان و پشتیبانی حرفه‌ای." />
        <meta name="keywords" content="طراحی رابط کاربری,طراحی و ساخت اپلیکیشن تحت وب,ساحت برنامه ios,ساخت برنامه اندروید" />
      </Helmet>
      <div className="application-container xs-d-none lg-d-block">
        <Header />
        <BannerApp />
        <div className="space-app-2"></div>
        <div
          id="app"
          className="xs-d-none l-d-flex container justify-content-between align-items-center"
        >
          <div>
            <div className="mb-60 position-relative">
              <p className="rtl c-white bold app-choose-header-fa">
                چرا خدمات طراحی و توسعه اپلیکیشن تیم عماد؟
              </p>
              <p className="orange-box position-absolute z-n-1"></p>
              <p className="rtl c-white-70">WHY YOU NEED TO CHOOSE US</p>
            </div>
            <div className="ml-80">
              <p className="app-choose-content rtl c-white-70">
                به هزار و یک دلیل میتوانید ما را به عنوان توسعه دهنده اپلیکیشن
                خود انتخاب کنید. سال ها سابقه کار، سرعت عمل بالا، هارد اسکیل و
                سافت اسکیل بالا و خلاقیت تنها و تنها بخش کوچکی از ویژگی های
                اعضای تیم ما است. <br />
                ما صرفا برای شما اپلیکیشن طراحی نمیکنیم. ما شما را راهنمایی
                میکنیم که چگونه پیشرفت کنید و از اپلیکیشن خود نهایت سود را
                ببرید.
              </p>
            </div>
            <div className="mt-55 d-flex justify-content-start">
              <Link className="link1 pointer prop" to='/contact'>
                <MainBtn text="بیشتر بدانید" />
              </Link>
              <div className="mr-16"></div>
              <Link to="/service" className="link1 prop">
                <SecondBtn text="سایر سرویس ها" />
              </Link>
            </div>
          </div>
          <div className="app-choose-space"></div>
          <div className="d-flex">
            <div className="mr-30">
              <div className="windows-item1 d-flex justify-content-center align-items-center">
                <img className="computer-icon" src={computer} />
                <p className="c-white">تعاملی برای همه دستگاه ها</p>
              </div>
              <div
                className="windows-item2 d-flex flex-column justify-content-center align-items-center"
                style={{ backgroundImage: "url(" + comput + ")" }}
              >
                <img className="computer-icon" src={factorsWhite} />
                <p className="c-white">آمار و ارقام نصب بالا</p>
              </div>
            </div>
            <div>
              <div
                className="windows-item3"
                style={{ backgroundImage: "url(" + comput + ")" }}
              ></div>
              <div className="windows-item4 bg-main d-flex flex-column justify-content-center align-items-center">
                <img className="headphone-icon" src={headphone} />
                <p className="c-white">پشتیبانی حرفه ای رایگان</p>
              </div>
            </div>
          </div>
        </div>

        <div className="xs-d-none l-d-flex mb-260 application-card-detail mt-150 d-flex justify-content-between">
          <div>
            <div className="mb-30">
              <p className="f-16 c-white-70 rtl text-align-justify">
                ما برای اجرای خواسته ی شما اول خودمون رو جای شما می گذاریم .
                مثلا چه انتظاری هایی داریم از کسی که پروژمون رو بهش می سپاریم ؟
                یا مثلا همیشه دنبال کسی میگردیم که بتونیم با خیال راحت پروژه را
                بهش بسپاریم تا بعد از تحویل گرفتن پروژه برای زمان از دست رفته
                تاسف نخوریم . همین طرز فکر باعث میشه ما بتونیم تو کار با شما
                ارتباط بهتری بگیریم و خواسته شما را تا جایی که میشه به بهترین
                شکل ممکن اجرا و تحویل دهیم . شرکت عماد هم بی شک مانند تمام شرکت
                های دنیا به جهت درامد فعالیت می کند با این تفاوت که معیار های
                دیگه ای هم برای ما مهم هست . و از مهم ترینشون میتونیم به رضایت
                مشتری اشاره کنیم . به ما اعتماد کنید نا امید نمیشید .
              </p>
            </div>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start">
                <Link className="link1 pointer prop" to='/contact'>
                  <MainBtn text="تماس با ما" />
                </Link>
                <div className="mr-16"></div>
                <Link to="/service" className="link1">
                  <SecondBtn text="خدمات و سرویس ها" />
                </Link>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {/* <div>
                  <img className="pointer range-arrow" src={al} />
                </div>
                <div className="slidecontainer mx-20">
                  <input
                    type="range"
                    min="1"
                    max="100"
                    value="0"
                    className="slider"
                    id="myRange"
                  />
                </div>
                <div>
                  <img className="pointer range-arrow" src={ar} />
                </div> */}
              </div>
            </div> 
          </div>
          <div className="to-img"></div>
          <div>
            <div className="d-flex pb-45 bb">
              <div className="mr-20">
                <p className="c-white bold f-30">
                  انعطاف پذیری در طراحی و اجرا
                </p>
              </div>
              <div>
                <img className="app-card-icon" src={pen} />
              </div>
            </div>
            <div>
              <div
                className="mt-45 app-card-img d-flex flex-column justify-content-center align-items-center"
                style={{ backgroundImage: "url(" + comput + ")" }}
              >
                <div className="app-card-img-inner d-flex flex-column justify-content-center align-items-center">
                  <img className="computer-icon" src={factorsWhite} />
                  <p className="f-16 c-white">آمار و ارقام نصب بالا</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xs-d-none l-d-block">
          <ScrollOne id="appscroll" />
          <div className="mb-150"></div>
        </div>
        <div className="xs-d-none l-d-block">
          <Improve />
          <div className="mb-200"></div>
          <div className="container">
            <MainForm />
          </div>
          <div className="mb-150"></div>
          <Footer />
        </div>
      </div >

      <div className="xs-d-block lg-d-none app-mobile-container">
        <div className="app-header-mobile">
          <HeaderMob />
        </div>
        <div className="arz-margin">
          <div className="app-banner-mobile">
            <img src={bannerApp} />
          </div>
          <div className="app-header1-mobile">
            <p>برنامه نویس حرف ای و مدرن</p>
          </div>
          <div className="app-header2-mobile">
            <p>اپلیکیشن اندروید و آی او اس</p>
          </div>
          <div className="app-header3-mobile">
            <p>خدمات حرفه ای طراحی اپلیکیشن</p>
          </div>
          <div className="app-text-mobile">
            <p>
              خدمات کسب و کار خودرا برای حرفه‌ای تر شدن به تیم ما بسپارید . ما با
              ارائه خدمات سطح بالا در ضمینه برانامه موبایل قطعا در مسیر کسب و کار دیجیتال به شما کمک
              خواهیم کرد
            </p>
          </div>
          <div className="app-main-btn-mobile position-absolute">
            <MainBtnOpen link="/contact" text="مشاوره رایگان برنامه موبایل" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Application;