import './Com.css';

function Org(){
    return (
        <div className='w-100 com-container d-flex justify-content-between align-items-center'>
            <div className='w-100'>
                <p className='c-white f-20 rtl text-align-left'>670,000 تومان</p>
            </div>
            <div className='com-label d-flex justify-content-center align-items-center'>
                <p className='c-white-70'>.org</p>
            </div>
        </div>
    );
}

export default Org;