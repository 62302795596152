import React, { useState } from 'react';
import axios from 'axios';
import './Form1.css';
import MainBtn from '../MainBtn/MainBtn.js';

function Form1() {
    const [subject, setSubject] = useState('');
    const [contact, setContact] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [statusType, setStatusType] = useState(''); // برای تعیین نوع پیام (موفقیت یا خطا)

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!subject || !contact || !name || !message) {
            setStatus('همه فیلدها باید پر شوند');
            setStatusType('error');
            return;
        }

        if (contact.length !== 11 || !/^\d+$/.test(contact)) {
            setStatus('شماره موبایل خود را به صورت انگلیسی و همراه با ۰ وارد نمایید');
            setStatusType('error');
            return;
        }

        try {
            await axios.post('https://emad.team/send-email', {
                subject,
                contact,
                name,
                message
            });

            setStatus('پیام با موفقیت ارسال شد');
            setStatusType('success');
        } catch (error) {
            console.error('Error sending message:', error);
            setStatus('چه عجیب! خطا خورد. دوباره امتحان کنید');
            setStatusType('error');
        }
    };

    return (
        <form className="d-flex justify-content-center align-items-center w-100" onSubmit={handleSubmit}>
            <div className="form1-container-flex d-flex flex-column">
                <div className="d-flex justify-content-between flex-column flex-md-row">
                    <div className='flex-fill xs-mb-30 m-mb-0 m-mr-30'>
                        <input 
                            type="text"
                            className="rtl c-white" 
                            placeholder="موضوع"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required 
                        />
                    </div>
                    <div className='flex-fill xs-mb-30 m-mb-0 m-mr-30'>
                        <input 
                            type="text"
                            className="rtl c-white" 
                            placeholder="شماره موبایل"
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                            pattern="\d*"
                            maxLength="11"
                            required 
                        />
                    </div>
                    <div className='flex-fill xs-mb-30 m-mb-0'>
                        <input 
                            type="text"
                            className="rtl c-white" 
                            placeholder="نام شما"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required 
                        />
                    </div>
                </div>
                <div>
                    <textarea 
                        placeholder="پیام شما" 
                        className="rtl w-100 c-white"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                    <MainBtn text='ارسال پیام' type="submit" />
                </div>
                {status && <p className={`status-message ${statusType}`}>{status}</p>}
            </div>
        </form>
    );
}

export default Form1;
