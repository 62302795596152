import './SecondBtn.css';

function SecondBtn(props) {
    return (
        <div className='secondbtn-container'>
            <button className='d-flex justify-content-center align-items-center c-white'>
                { props.text }
            </button>
        </div>
    );
}

export default SecondBtn;