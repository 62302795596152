// App.js
import './App.css';
import Redirect from './Components/Cpanel/Redirect';
// router
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// pages
import PageOne from './Pages/PageOne/PageOne.js';
import PageTwo from './Pages/PageTwo/PageTwo.js';
import PageThree from './Pages/PageThree/PageThree.js';
import PageFour from './Pages/PageFour/PageFour.js';
import PageFive from './Pages/PageFive/PageFive.js';
import PageSix from './Pages/PageSix/PageSix.js';
import PageSeven from './Pages/PageSeven/PageSeven';
import SignUp from './Pages/SignUp/SignUp.js';
// import Login from './Components/Cpanel/Login.js';
// import Hosting from './Components/Cpanel/Hosting.js';
import HostingView from './Pages/HostingView/HostingView';
// import Digital from './Pages/Digital/Digital';
import Instagram from './Pages/Instagram/Instagram';
import Design from './Pages/Design/Design';
import WebDesign from './Pages/WebDesign/WebDesign';
import Application from './Pages/Application/Application';
// import HostingPanel from "./sections/HostingPanel/HostingPanel";
// import HostingDomain from "./sections/HostingDomain/HostingDomain";
// import HostingConfig from "./sections/HostingConfig/HostingConfig";
// import HostingDomainChoose from "./sections/HostingDomainChoose/HostingDomainChoose";
// import HostingShop from "./sections/HostingShop/HostingShop";
// import HostingBuy from "./sections/HostingBuy/HostingBuy";
import InstaPopUp from './Pages/InstaPopUp/InstaPopUp';
import { useEffect } from 'react';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<PageOne />} />
        <Route path='/login/' element={<Redirect to="https://emad.team/hosting/index.php?m=vo_reglogin" />} />
        <Route path="/signup/" element={<SignUp />} />
        <Route path="/blog/" element={<PageSix />} />
        <Route path="/contact/" element={<PageTwo />} />
        <Route path="/about/" element={<PageThree />} />
        <Route path="/service/" element={<PageFour />} />
        <Route path="/blog/:slug/" element={<PageSeven />} />
        <Route path="/FAQs/" element={<PageFive />} />
        <Route path='/demohosting/' element={<HostingView />} />
        <Route path='/hosting/' element={<Redirect to="https://emad.team/hosting/" />} /> 
        <Route path='/design/' element={<Design />} />
        <Route path='/web-design/' element={<WebDesign />} />
        <Route path='/application/' element={<Application />} />
        <Route path='/contact/' element={<Instagram />} />
        <Route path='*' element={<PageOne />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
