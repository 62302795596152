import "./HostingServiceCard.css";
import Hosting from "../../Images/hosting.png";
import { useEffect } from "react";

function HostingServiceCard(props) {
  useEffect(() => {
    if (props.style == "active") {
      document
        .getElementById(props.id)
        .classList.add("hosting-service-card-act");
      document
        .getElementById(props.id)
        .classList.remove("hosting-service-card-container");
    }
  }, []);
  return (
    <div
      id={props.id}
      className="hosting-service-card-container d-flex flex-column"
    >
      <div className="w-100 hosting-service-card-img d-flex justify-content-center">
        <img src={Hosting} />
      </div>
      <div className="w-100 mt-30 mb-20">
        <p className="text-align-center title f-20 bold">{props.title}</p>
      </div>
      <div className="w-100 mb-20">
        <p className="w-100 c-white-50 text-align-justify rtl">
          {props.caption}
        </p>
      </div>
      <div className="hosting-service-card-btn">
        <p className="f-16 bold">انتخاب پلن</p>
      </div>
    </div>
  );
}

export default HostingServiceCard;
