import './Blog.css';
import PostCard from '../../Components/PostCard/PostCard.js';
import Pagination from '../../Components/Pagination/Pagination.js';
import axios from 'axios';
import { useEffect, useState } from 'react';

function Blog() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const postsPerPage = 6;

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('https://emad.team/mag/wp-json/wp/v2/posts');
                const postsWithImages = await Promise.all(response.data.map(async post => {
                    if (post.featured_media) {
                        const mediaResponse = await axios.get(`https://emad.team/mag/wp-json/wp/v2/media/${post.featured_media}`);
                        post.featured_media_url = mediaResponse.data.source_url;
                    }
                    return post;
                }));
                setPosts(postsWithImages);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const displayedPosts = posts.slice(currentPage * postsPerPage, (currentPage + 1) * postsPerPage);

    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className='d-flex flex-column align-items-center w-100'>
            {loading ? (
                <div className='w-100 d-flex justify-content-center'>
                    <div className='d-flex flex-column align-items-center'>
                        {Array(3).fill().map((_, index) => (
                            <div key={index} className='w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center two-post-card'>
                                <PostCard width='x' />
                            </div>
                        ))}
                    </div>
                    <div className='d-flex flex-column align-items-center'>
                        {Array(3).fill().map((_, index) => (
                            <div key={index} className='w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center two-post-card'>
                                <PostCard width='x' />
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className='w-100 d-flex justify-content-center'>
                    <div className='d-flex flex-column align-items-center mob'>
                        {displayedPosts.slice(0, 3).map(post => (
                            <PostCard
                                key={post.id}
                                width='x'
                                title={post.title.rendered}
                                excerpt={post.excerpt.rendered}
                                date={post.date}
                                author={post.author}
                                categories={post.categories}
                                image={post.featured_media_url}
                                id={post.id}
                                slug={post.slug}
                            />
                        ))}
                    </div>
                    <div className='d-flex flex-column align-items-center mob'>
                        {displayedPosts.slice(3, 6).map(post => (
                            <PostCard
                                key={post.id}
                                width='x'
                                title={post.title.rendered}
                                excerpt={post.excerpt.rendered}
                                date={post.date}
                                author={post.author}
                                categories={post.categories}
                                image={post.featured_media_url}
                                id={post.id}
                                slug={post.slug}
                            />
                        ))}
                    </div>
                </div>
            )}
            <Pagination
                pageCount={Math.ceil(posts.length / postsPerPage)}
                onPageChange={handlePageClick}
            />
        </div>
    );
}

export default Blog;
