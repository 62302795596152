import "./MainBtnOpen.css";
import arrowLeft from "../../Images/arrowleft.png";

function MainBtnOpen(props) {
  const handleClick = (e) => {
    if (props.link) {
      e.preventDefault();
      window.location.href = props.link;
    }
  };

  return (
    <div className="mainbtn-open-container">
      <button 
        className="bg-main" 
        type={props.type || "button"}
        onClick={handleClick}
      >
        <span>
          <img
            className="mainbtn-icon"
            src={arrowLeft}
            alt="right to left arrow icon"
          />
        </span>
        <span className="mainbtn-text c-white">{props.text}</span>
      </button>
    </div>
  );
}

export default MainBtnOpen;
