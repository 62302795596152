import "./BannerWeb.css";
import bannerWeb from "../../Images/bannerWeb.png";
import MainBtn from "../../Components/MainBtn/MainBtn.js";
import SecondBtn from "../../Components/SecondBtn/SecondBtn.js";
import mouse from "../../Images/mouse.png";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen";
import { Link } from "react-router-dom";
import cric from "../../Images/circ.png";
import dots from "../../Images/Dots.png";
import { useState, useEffect } from "react";

function BannerWeb() {
  const handleScroll = () => {
    document.getElementById("web-design").scrollIntoView();
  };
  return (
    <>
      <div className="xs-d-none l-d-flex container-fluid banner-design-web">
        <div className="banner-web2-container-inside container">
          <div className="position-relative web-baner d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-end align-items-lg-center w-100 ">
            <div className="l-mr-160 banner-web-image-container">
              <img src={bannerWeb} />
            </div>
            <div className="banner-web-title-container d-flex flex-column align-items-end">
              <div className="title-1">
                <p className="c-white bold rtl">طراحی سایت کسب و کار</p>
              </div>
              <div className="title-1">
                <p className="c-white bold rtl">خود را به ما بسپارید</p>
              </div>
              <div className="title-2 mb-40i">
                <p className="c-white rtl">
                  <span className="c-main">خدمات حرفه ای طراحی سایت</span>
                </p>
              </div>
              <div className="title-text xs-mb-0 mb-40i">
                <p className="c-white-70 rtl text-align-justify">
                  آیا فکر میکنید که وقت این است که کسب و کار خودتان را به یک کسب
                  و کار اینترنتی تبدیل و فروش خود را چند برابر کنید؟ میتوانید از
                  کمک ما استفاده کنید! طراحی وبسایت خود را به ما بسپارید تا
                  برایتان یک وبسایت حرفه ای طراحی کنیم و کسب و کار شما را به یک
                  کسب و کار مجازی تبدیل کنیم.
                </p>
              </div>
              <div className="d-flex justify-content-end w-100">
                <a className="link1 pointer bp" href="#web-design">
                  <MainBtn text="بیشتر بدانید" />
                </a>
                <div className="l-btn"></div>
                <Link className="xs-d-none l-d-block link1 poniter bp" to="/hosting">
                  <SecondBtn text="هاست و دامنه" />
                </Link>
              </div>
            </div>
            <img src={cric} className="cric" />
            <img src={dots} className="dots" />
          </div>
        </div>
        <div className="xs-d-none l-d-flex mouse-web-container d-flex flex-column align-items-center">
          <img className="mb-10 pointer" src={mouse} onClick={handleScroll} />
          <p className="c-white-20 default">اسکرول کنید</p>
        </div>
      </div>

      <div className="xs-d-flex l-d-none container-fluid banner-wbmob-container flex-column justify-content-start align-items-center overnone">
        <div className="flex-fill d-flex flex-column justify-content-start align-items-center">
          <div className="banner-webmob-image-container">
            <img src={bannerWeb} />
          </div>
          <div className="banner-design-title-container d-flex flex-column align-items-end">
            <div className="title-1">
              <p className="c-white rtl">طراحی سایت کسب و کار</p>
            </div>
            <div className="title-1">
              <p className="c-white rtl">خود را به ما بسپارید</p>
            </div>
            <div className="title-2">
              <p className="c-white rtl mb-0">
                <span className="c-main">خدمات حرفه ای طراحی سایت</span>
              </p>
            </div>
            <div className="title-text">
              <p className="c-white-70 rtl text-align-justify">
                آیا فکر میکنید که وقت این است که کسب و کار خودتان را به یک کسب و
                کار اینترنتی تبدیل و فروش خود را چند برابر کنید؟ میتوانید از کمک
                ما استفاده کنید! طراحی وبسایت خود را به ما بسپارید تا برایتان یک
                وبسایت حرفه ای طراحی کنیم و کسب و کار شما را به یک کسب و کار
                مجازی تبدیل کنیم.
              </p>
            </div>
            <div className="w-100 btn-banner-appmod-container">
              <a className="link1" href="#design">
                <MainBtnOpen text="بیشتر بدانید" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerWeb;
