import './ContactLine.css';
import contact from '../../Images/contact.png';
import line from '../../Images/line.png';

function ContactLine(){
    return (
        <div className='contact-line d-flex justify-content-center align-items-center' style={{backgroundImage: 'url('+line+')'}}>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column align-items-center'>
                    <div>
                        <p className='c-white bold fa'>تماس با ما</p>
                    </div>
                    <div>
                        <p className='c-white en'>CONTACT US</p>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <img src={contact} />
                </div>
            </div>
        </div>
    );
}

export default ContactLine;