import './LoremLine.css';
import blog from '../../Images/blog.png';
import line from '../../Images/line.png';

function LoremLine({ title, categories }) {
    return (
        <div className='contact-line d-flex justify-content-center align-items-center' style={{ backgroundImage: 'url(' + line + ')' }}>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column align-items-end'>
                    <div>
                        <p className='c-white bold fa'>{title}</p>
                    </div>
                    <div>
                        <p className='c-white en'>{categories}</p>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <img src={blog} alt="Blog" />
                </div>
            </div>
        </div>
    );
}

export default LoremLine;
