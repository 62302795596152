import "./WebDesign.css";
import smile from "../../Images/smile.png";
import tik from "../../Images/tik.png";
import medal from "../../Images/medal.png";
import nemoodar from "../../Images/nemoodar.png";
import bg from "../../Images/bg.jpeg";
import men from "../../Images/men.png";
import DropQuestion from "../../Components/DropQuestion/DropQuestion";
import play from "../../Images/play.png";
import MainBtn from "../../Components/MainBtn/MainBtn";
import SecondBtn from "../../Components/SecondBtn/SecondBtn";
import MainBtnWhite from "../../Components/MainBtnWhite/MainBtnWhite";
import ScrollOne from "../../sections/ScrollOne/ScrollOne";
import Improve from "../../sections/Improve/Improve.js";
import MainForm from "../../sections/MainForm/MainForm.js";
import Footer from "../../sections/Footer/Footer.js";
import Header from "../../sections/Header/Header.js";
import HeaderMob from "../../sections/HeaderMob/HeaderMob";
import BannerWeb from "../../sections/BannerWeb/BannerWeb";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen";
import bannerWeb from "../../Images/bannerWeb.png";
import WebDesignvideos from "../../videos/WebDesign-Emad-Team.mp4"
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';



function WebDesign() {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>تیم عماد | خدمات طراحی وبسایت</title>
        <meta name="description" content="صفحه خدمات طراحی وبسایت تیم عماد. طراحی سایت اختصاصی ، طراحی سایت وردپرس ، طراحی قالب و افزونه وردپرس." />
        <meta name="keywords" content="تیم عماد, طراحی وب سایت, طراحی سایت وردپرس, طراحی قالب و افزونه وردپرس,طراحی سایت اختصاصی, خدمات طراحی سایت" />
      </Helmet>
      <div className="xs-d-none l-d-block web-design-container">
        <Header />
        <BannerWeb />
        <div className="l-mb-150"></div>
        <div id="web-design" className="xs-d-none l-d-block container mb-160">
          <div className="d-flex align-items-center">
            <div className="h-100 mr-20 d-flex align-items-center">
              <div className="cardfi d-flex flex-column justify-content-center align-items-center">
                <div className="card5 d-flex flex-row justify-content-end align-items-center">
                  <div className="card-txt">
                    <p className="card-txt1 rtl c-white justify-content-end">
                      %99
                    </p>
                    <p className="card-txt2 rtl c-white-50 justify-content-end">
                      تحویل به موقع
                    </p>
                  </div>
                  <div className="card-container d-flex">
                    <img className="card-icon2" src={tik} alt="" />
                  </div>
                </div>
                <div className="card5 d-flex flex-row justify-content-end align-items-center">
                  <div className="card-txt">
                    <p className="card-txt1 rtl c-white justify-content-end">
                      %96
                    </p>
                    <p className="card-txt2 rtl c-white-50 justify-content-end">
                      درصد رضایت مشتریان
                    </p>
                  </div>
                  <div className="card-container2 d-flex">
                    <img className="card-icon" src={smile} alt="" />
                  </div>
                </div>
                <div className="card5 d-flex flex-row justify-content-end align-items-center">
                  <div className="card-txt">
                    <p className="card-txt1 rtl c-white justify-content-end">
                      %93
                    </p>
                    <p className="card-txt2 rtl c-white-50 justify-content-end">
                      رضایت مشتریان از پشتیبانی
                    </p>
                  </div>
                  <div className="card-container3 d-flex">
                    <img className="card-icon3" src={medal} alt="" />
                  </div>
                </div>
                <div className="card5 d-flex flex-row justify-content-end align-items-center">
                  <div className="card-txt">
                    <p className="card-txt1 rtl c-white justify-content-end">
                      %99
                    </p>
                    <p className="card-txt2 rtl c-white-50 justify-content-end">
                      آپتایم سرویس ها
                    </p>
                  </div>
                  <div className="card-container4 d-flex">
                    <img className="card-icon4" src={nemoodar} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-card">
                <img src={bg} />
                <div className="w-100 bg-card-content">
                  <div className="mb-30">
                    <p className="rtl c-white bold f-19 text-align-center">
                      به ما بپیوندید و{" "}
                      <span className="c-inner"> بهره وری </span> خود را افزایش
                      دهید
                    </p>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <Link className="link1" to="/contact">
                      <MainBtnWhite text="مشاوره رایگان" />
                    </Link>
                    <div className="mr-16"></div>
                    <a className="link1 pointer" href="#FFFqa">
                      <SecondBtn text="سوالی هست ؟" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="w-100 h-100 d-flex flex-column align-items-end">
                <div className="mb-40 position-relative">
                  <p className="rtl c-white bold f-30">
                    چرا خدمات طراحی وبسایت تیم عماد؟
                  </p>
                  <p className="orange-box position-absolute z-n-1"></p>
                  <p className="rtl c-white-70 f-16">
                    WHY YOU NEED TO CHOOSE US
                  </p>
                </div>
                <div className="ml-45">
                  <p className="text-align-justify rtl c-white-70 f-16">
                    تعداد بسیاری طراح سایت ایرانی و خارجی وجود دارد. با این
                    اوصاف شاید از خود بپرسید چرا باید طراحی وبسایتتان را به ما
                    بسپارید. اگر بخواهیم به این سوال پاسخ دهیم، پاسخی به وسعت یک
                    کتاب نیاز است؛ تا در پاسخ تمام مزایا سفارش خدمات از ما را
                    شرح داده باشد.
                    <br />
                    تجربه بالا، خلاقیت و اخلاق حرفه ای تنها بخش کوچکی از دلایل
                    چرایی انتخاب ما است. تنها هارد اسکیل(Hard Skills) و سافت
                    اسکیل(Soft Skills) بالا اعضای تیم عماد نیست که تیم عمار را
                    خاص میکند. ما شما را تا مسیر پیشرفت کسب و کارتان همراهی
                    میکنیم و شما را در مسیر درست قرار میدهیم.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xs-d-none l-d-block container mb-150">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column align-items-center w-530 px-19">
              <div className="men-container mb-37">
                <div className="video-container">
                  <video className="men-bg webdesignvideo" ref={videoRef} >
                    <source src={WebDesignvideos} type="video/mp4" />
                    مرورگر شما از ویدئو پشتیبانی نمی‌کند.
                  </video>
                  {!playing && (
                    <div className="men-play d-flex justify-content-center align-items-center bg-main" onClick={handlePlayClick}>
                      <img src={play} alt="Play Button" className="play-button" />
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-30">
                <p className="c-white-80 f-14 rtl text-align-justify lh-26">
                  تیم عماد با طراحی وب‌سایت‌های حرفه‌ای، کسب‌ و کار شما را به اوج می‌رساند! با ترکیب خلاقیت و مهارت‌های فنی، سایت‌هایی زیبا و کاربردی ایجاد می‌کنیم که تجربه کاربری بی‌نظیری را ارائه می‌دهند. برای موفقیت در دنیای دیجیتال، به یک وب‌سایت حرفه‌ای نیاز دارید!
                </p>
              </div>
              <div className="w-100 d-flex justify-content-start">
                <Link className="link1 pointer" to="/contact">
                  <MainBtn text="مشاوره رایگان" />
                </Link>
                <div className="mr-18"></div>
                <a className="link1 pointer" href="#webscroll">
                  <SecondBtn text="نمونه کار ها" />
                </a>
              </div>
            </div>

            <div id="FFFqa">
              <div >
                <div className="position-relative mb-40">
                  <p className="f-20 rtl c-white bold mb-2">سوالات متداول</p>
                  <p className="f-10 c-white-50 rtl">FAQs</p>
                  <p className="orange-box orange-web-faqs position-absolute z-n-1"></p>
                </div>
              </div>
              <div className="pointer">
                <DropQuestion
                  id="webdis1"
                  title="هزینه راه اندازی یک وبسایت چقدر هست؟"
                  answer="هزینه پروژه بستگی به نیاز های شما دارد . این به این معنی هست که امکان
          نداره سایت دیجیکالا را به مبلغ ۵۰۰ هزار تومان به صورت اختصاصی راه
          اندازی کنید چرا که کلی امکانات خاص در این سایت وجود دارد که ساده نیست
          ! پس هزینه با توجه به نیاز شما مشخص می شود . اما خیالتان راحت باشد .
          در صورتی که شما نیاز به یک وب سایت داشته باشید مطمئن باشید تیم ما
          افتخار کار با شما را به پول ترجیح میده . پس نگران نباشید تماس بگیرید
          تا باهم بیشتر صحبت کنیم ."
                />
                <div className="mb-28"></div>
                <DropQuestion
                  id="webdis2"
                  title="آیا همه هزینه پروژه باید ابتدا پرداخت شود؟"
                  answer="خیر ، برای دریافت هزینه به توافق میرسیم ولی معمولا بخشی از مبلغ در
          ابتدای پروژه دریافت می شود"
                />
                <div className="mb-28"></div>
                <DropQuestion
                  id="webdis3"
                  title="داشتن یک وب سایت چقدر به کسب و کار من کمک می‌کند؟"
                  answer="بزارید این سوال رو اینطوری جواب بدیم اگر شما یک فروشگاه داشته باشید
          نیاز دارین برای استراحت یه تایمی درب مغازه را ببندین اما وب سایت خستگی
          ناپذیره و نیاز به استراحت ندارد . ۲۴ ساعته آماده نمایش یا فروختن برای
          شما حتی زمانی که شما در حال استراحت هستین."
                />
                <div className="mb-28"></div>
                <DropQuestion
                  id="webdis4"
                  title="چقد زمان می‌برد سایت من آماده شود؟"
                  answer="جواب این سوال هم مشابه بحث هزینه می باشد . مدت زمان طراحی سایت شما
          بستگی به امکانات و ویژگی های مد نظر شما را دارد ."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="xs-d-none l-d-block">
          <ScrollOne id="webscroll" />
          <div className="space-web-3"></div>
        </div>
        <div className="xs-d-none l-d-block">
          <Improve />
          <div className="design-space"></div>
          <div className="container">
            <MainForm />
          </div>
          <div className="space-web-1"></div>
          <Footer />
        </div>
      </div>

      <div className="xs-d-block l-d-none web-mobile-container">
        <div className="web-header-mobile">
          <HeaderMob />
        </div>
        <div className="arz-margin">
          <div className="web-banner-mobile">
            <img src={bannerWeb} />
          </div>
          <div className="web-title-mobile-1">
            <p>طراحی سایت کسب و کار</p>
          </div>
          <div className="web-title-mobile-2">
            <p>خود را به ما بسپارید</p>
          </div>
          <div className="web-title-mobile-3">
            <p>خدمات حرفه‌ای طراحی سایت</p>
          </div>
          <div className="web-title-text-mobile">
            <p>
              خدمات کسب و کار خود را برای حرفه‌ای تر شدن به تیم ما بسپارید . ما با
              ارائه خدمات سطح بالا در ضمینه طراحی سایت و فروش آنلاین به شما کمک
              خواهیم کرد
            </p>
          </div>
          <div className="web-main-btn-mobile position-absolute">
            <MainBtnOpen link="/contact" text="مشاوره رایگان طراحی سایت" />
          </div>
        </div>
      </div>
    </>
  );
}

export default WebDesign;
