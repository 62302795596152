import './FooterMob.css';
import shop from '../../Images/shop.png';
import home from '../../Images/mobilehome.png';
import user from '../../Images/mobileuser.png';
import { Link } from 'react-router-dom';

function FooterMob() {
    return(
        <div className='fmp'>
            <div className='container container footer-mob-container'>
                <Link className='d-block pointer link1' to="/"><img className="" src={shop} /></Link>
                <Link className='d-block pointer link1' to="/"><img className="home-icon" src={home} /></Link>
                <Link className='d-block pointer link1' to="/login"><img className="" src={user} /></Link>
            </div>
        </div>
    );
}

export default FooterMob;