import './XtraPostCard.css';
import card from '../../Images/card.png';
import facebookMessage from '../../Images/facebookMessage.png';
import whatsappMain from '../../Images/whatsapp-main.png';
import twitterMain from '../../Images/twitter-main.png';
import instagramMain from '../../Images/instagram-main.png';
import telegramMain from '../../Images/telegram-main.png';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import jalaali from 'jalaali-js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function XtraPostCard() {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://emad.team/mag/wp-json/wp/v2/posts?slug=${slug}`);
                const post = response.data[0];

                if (post.featured_media) {
                    const mediaResponse = await axios.get(`https://emad.team/mag/wp-json/wp/v2/media/${post.featured_media}`);
                    post.featured_media_url = mediaResponse.data.source_url;
                }

                if (post.categories.length > 0) {
                    const categoryNames = await Promise.all(post.categories.map(async (catId) => {
                        const categoryResponse = await axios.get(`https://emad.team/mag/wp-json/wp/v2/categories/${catId}`);
                        return categoryResponse.data.name;
                    }));
                    post.category_names = categoryNames.join(', ');
                }

                // دریافت داده‌های سئو از Rank Math
                const seoDataResponse = await axios.get(`https://emad.team/mag/wp-json/rankmath/v1/getHead?url=${encodeURIComponent(post.link)}`);
                const seoData = seoDataResponse.data.head;

                // استخراج عنوان و توضیحات از تگ‌های متا
                const titleMatch = seoData.match(/<meta property="og:title" content="([^"]+)"/);
                const descriptionMatch = seoData.match(/<meta property="og:description" content="([^"]+)"/);

                post.rank_math_seo_title = titleMatch ? titleMatch[1] : '';
                post.rank_math_seo_description = descriptionMatch ? descriptionMatch[1] : '';

                // For Console 
                window.post = post;

                // console.log('Rank Math SEO Title:', post.rank_math_seo_title);
                // console.log('Rank Math SEO Description:', post.rank_math_seo_description);


                // تبدیل تاریخ میلادی به شمسی
                const gregorianDate = new Date(post.date);
                const jalaaliDate = jalaali.toJalaali(gregorianDate);
                post.formatted_date = `${jalaaliDate.jy} / ${jalaaliDate.jm} / ${jalaaliDate.jd}`;

                setPost(post);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);

    if (loading) return <Skeleton height={300} baseColor="#222222" highlightColor="#444444" />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="post-card-box d-flex flex-column justify-content-between align-items-end w-100">
            <div className="img-post-card-container-d">
                <div className='xtra-post-card-img position-relative' style={{ backgroundImage: `url(${post.featured_media_url || card})` }}>
                    <div><span className="xs-d-block l-d-none epost-card-set c-white">{post.category_names || <Skeleton width={80} baseColor="#222222" highlightColor="#444444" />}</span></div>
                    <div className='xs-d-none l-d-flex w-100 h-100 flex-row justify-content-between align-items-end inner-extra-img'>
                        <div className='d-flex'>
                            <div className='xtra-post-card-img-icon pointer'><img src={facebookMessage} alt="Facebook" /></div>
                            <div className='xtra-post-card-img-icon pointer'><img src={whatsappMain} alt="WhatsApp" /></div>
                            <div className='xtra-post-card-img-icon pointer'><img src={twitterMain} alt="Twitter" /></div>
                            <div className='xtra-post-card-img-icon pointer'><img src={instagramMain} alt="Instagram" /></div>
                            <div className='xtra-post-card-img-icon pointer'><img src={telegramMain} alt="Telegram" /></div>
                        </div>
                        <div className='d-flex flex-column justify-content-end align-items-end'>
                            <div className="xtra-post-card-set c-white">{post.category_names || <Skeleton width={80} baseColor="#222222" highlightColor="#444444" />}</div>
                            <div>
                                <p className='d-flex align-items-center'><span className='c-white-70 xtra-inner-text-date'>{post.formatted_date || <Skeleton width={50} baseColor="#222222" highlightColor="#444444" />}</span><span className='c-white xtra-inner-text-second'></span></p>
                            </div>
                            <div>
                                {/* <h1 className='c-white bold xtra-inner-text-main'>{post.rank_math_seo_title || post.title.rendered || <Skeleton width={200} baseColor="#222222" highlightColor="#444444" />}</h1> */}
                                <h1 className='c-white bold xtra-inner-text-main'>{post.title.rendered || <Skeleton width={200} baseColor="#222222" highlightColor="#444444" />}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-center w-100 xtra-post-card-bottom-container">
                <div className='w-100 rtl xs-d-block l-d-none'>
                    <div>
                        <p className='c-main f-18 bold mb-20'>{post.rank_math_seo_title || post.title.rendered || <Skeleton width={200} baseColor="#222222" highlightColor="#444444" />}</p>
                    </div>
                </div>
                <div className="d-flex flex-row-reverse justify-content-start w-100 rtl xtra-post-card-text-container">
                    {post.content.rendered ? (
                        <p className="xtra-post-card-text" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                    ) : (
                        <Skeleton count={10} baseColor="#222222" highlightColor="#444444" />
                    )}
                </div>
                <div className='w-100 xs-d-flex l-d-none xs-icons justify-content-evenly'>
                    <div className='xxtra-post-card-img-icon pointer'><img src={facebookMessage} alt="Facebook" /></div>
                    <div className='xxtra-post-card-img-icon pointer'><img src={whatsappMain} alt="WhatsApp" /></div>
                    <div className='xxtra-post-card-img-icon pointer'><img src={twitterMain} alt="Twitter" /></div>
                    <div className='xxtra-post-card-img-icon pointer'><img src={instagramMain} alt="Instagram" /></div>
                    <div className='xxtra-post-card-img-icon pointer'><img src={telegramMain} alt="Telegram" /></div>
                </div>
            </div>
        </div>
    );
}

export default XtraPostCard;
