import './MainBtnWhite.css';
import arrowLeftB from '../../Images/arrowleftb.png';

function MainBtnWhite(props){
    return (
        <div className='mainbtnwhite-container'>
            <button className='bg-white'>
            <span><img className="mainbtnwhitein-icon" src={arrowLeftB} alt="right to left arrow icon" /></span>
                <span className='mainbtnwhite-text c-black'>{ props.text }</span>
            </button>
        </div>
    );
}

export default MainBtnWhite;