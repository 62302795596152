import './PageTwo.css';
import ContactLine from "../../Components/ContactLine/ContactLine.js"
import Contact from '../../sections/Contact/Contact.js';
import Header from '../../sections/Header/Header.js';
import Improve from '../../sections/Improve/Improve';
import Footer from '../../sections/Footer/Footer';
import MainForm from '../../sections/MainForm/MainForm';
import HeaderContact from '../../sections/HeaderContact/HeaderContact';
import { useState, useEffect } from 'react';
import FooterMob from '../../sections/FooterMob/FooterMob';
import { Helmet } from 'react-helmet';

function PageTwo() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div className='page-two-container'>
            <Helmet>
                <title>تماس با ما - تیم عماد</title>
                <meta name="description" content="صفحه تماس با تیم عماد" />
                <meta name="keywords" content="تیم عماد, تماس, ارتباط" />
            </Helmet>
            <div className='xs-d-none l-d-block'>
                <Header />
            </div>
            <div className='xs-d-block l-d-none container'>
                <HeaderContact />
            </div>
            <div className='space-2-1'></div>
            <div className='xs-d-none l-d-block'>
                <ContactLine />
            </div>
            <div className='space-2-1'></div>
            <Contact />
            <div className='space-2-4'></div>
            <div className='xs-d-block l-d-none'>
                <FooterMob />
            </div>
            <div className='xs-mb-0 l-mb-150'></div>
            <div className='xs-d-none l-d-block'>
                <Improve />
                <div className='space-2-2'></div>
                <div className='container'>
                    <MainForm />
                </div>
                <div className='space-2-3'></div>
                <Footer />
            </div>
        </div>
    );
}

export default PageTwo;
