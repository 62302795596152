import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Post.css';
import PostCard from '../../Components/PostCard/PostCard.js';
import blog from '../../Images/blog.png';
import al from '../../Images/al.png';
import ar from '../../Images/right.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Post(props) {
    const [slideIndex, setSlideIndex] = useState(1);
    const [style, setStyle] = useState('container');
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const showclick = (ev) => { };

    const plusSlides = (n) => {
        const newIndex = slideIndex + n;
        setSlideIndex(newIndex);
        showSlides(newIndex);
    }

    const currentSlide = (n) => {
        setSlideIndex(n);
        showSlides(n);
    }

    const showSlides = (n) => {
        let s = n;
        let slides = document.getElementsByClassName('mySlides');
        let dots = document.getElementsByClassName('dot');
        if (n > slides.length) {
            s = 1;
        }
        if (n < 1) {
            s = slides.length;
        }
        setSlideIndex(s);
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (let i = 0; i < dots.length; i++) {
            dots[i].classList.remove("active");
        }
        if (slides[s - 1]) {
            slides[s - 1].style.display = "block";
            dots[s - 1].classList.add("active");
        }
    }

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('https://emad.team/mag/wp-json/wp/v2/posts');
                const allPosts = response.data;

                // انتخاب سه پست تصادفی
                const randomPosts = [];
                while (randomPosts.length < 3 && allPosts.length > 0) {
                    const randomIndex = Math.floor(Math.random() * allPosts.length);
                    randomPosts.push(allPosts.splice(randomIndex, 1)[0]);
                }

                // دریافت تصاویر پست‌ها
                const postsWithImages = await Promise.all(randomPosts.map(async post => {
                    if (post.featured_media) {
                        const mediaResponse = await axios.get(`https://emad.team/mag/wp-json/wp/v2/media/${post.featured_media}`);
                        post.featured_media_url = mediaResponse.data.source_url;
                    }
                    return post;
                }));

                setPosts(postsWithImages);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    useEffect(() => {
        if (!loading) {
            showSlides(slideIndex);
        }
    }, [loading, slideIndex]);

    useEffect(() => {
        if (props.til === 'pad') {
            setStyle('container px3v');
        }
    }, [props.til]);

    if (error) return <p>Error: {error.message}</p>;

    const baseColor = "#222222";
    const highlightColor = "#444444";

    return (
        <div className={style}>
            <div className="d-flex flex-column align-items-center">
                <div className="blog-icon-container">
                    <img className="blog-icon" src={blog} alt="Blog Icon" />
                </div>
                <div className="blog-header-fa-container">
                    <p className="blog-header-fa c-white">مطالب وبلاگ</p>
                </div>
                <div className="blog-header-en-container">
                    <p className="blog-header-en c-white">BLOG POSTS</p>
                </div>
            </div>
            <div className="slideshow-container posts d-flex justify-content-center">
                {loading ? (
                    Array.from({ length: 3 }).map((_, index) => (
                        <div key={index} className="mySlides fade position-relative">
                            <div className='w-100 d-flex justify-content-center'>
                                <PostCard
                                    width="e"
                                    title={<Skeleton width={150} baseColor={baseColor} highlightColor={highlightColor} />}
                                    excerpt={<Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} />}
                                    date={<Skeleton width={80} baseColor={baseColor} highlightColor={highlightColor} />}
                                    categories={<Skeleton width={80} baseColor={baseColor} highlightColor={highlightColor} />}
                                    image={<Skeleton height={200} width="100%" baseColor={baseColor} highlightColor={highlightColor} />}
                                    id={<Skeleton width={50} baseColor={baseColor} highlightColor={highlightColor} />}
                                    slug={<Skeleton width={50} baseColor={baseColor} highlightColor={highlightColor} />}
                                />
                            </div>
                            <div onMouseDown={() => { plusSlides(1) }} className='rth'></div>
                            <div onMouseDown={() => { plusSlides(-1) }} className='lth'></div>
                        </div>
                    ))
                ) : (
                    posts.map((post, index) => (
                        <div key={post.id} className="mySlides fade position-relative">
                            <div className='w-100 d-flex justify-content-center'>
                                <PostCard
                                    width="e"
                                    title={post.title.rendered}
                                    excerpt={post.excerpt.rendered}
                                    date={post.date}
                                    author={post.author}
                                    categories={post.categories}
                                    image={post.featured_media_url}
                                    id={post.id}
                                    slug={post.slug}
                                />
                            </div>
                            <div onMouseDown={() => { plusSlides(1) }} className='rth'></div>
                            <div onMouseDown={() => { plusSlides(-1) }} className='lth'></div>
                        </div>
                    ))
                )}
            </div>
            <div className='dotted-container'>
                <img src={al} className="al-doti" onClick={() => { plusSlides(-1) }} alt="Previous" />
                <span className="dot" onClick={() => { currentSlide(1) }}></span>
                <span className="dot" onClick={() => { currentSlide(2) }}></span>
                <span className="dot" onClick={() => { currentSlide(3) }}></span>
                <img src={ar} className="ar-doti" onClick={() => { plusSlides(1) }} alt="Next" />
            </div>
        </div>
    );
}

export default Post;
