import './PageSeven.css';
import './custom-skeleton.css';
import LoremLine from '../../Components/LoremLine/LoremLine.js';
import XtraPostCard from '../../Components/XtraPostCard/XtraPostCard.js';
import CommentsForm from '../../sections/CommentsForm/CommentsForm.js';
import RelatedPost from '../../sections/RelatedPost/RelatedPost.js';
import Header from '../../sections/Header/Header.js';
import Improve from '../../sections/Improve/Improve.js';
import MainForm from '../../sections/MainForm/MainForm.js';
import Footer from '../../sections/Footer/Footer.js';
import HeaderBlog from '../../sections/HeaderBlog/HeaderBlog.js';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import PostD from '../../sections/PostD/PostD';
import FooterMob from '../../sections/FooterMob/FooterMob';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from 'react-helmet'; // اضافه کردن Helmet

function PageSeven() {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://emad.team/mag/wp-json/wp/v2/posts?slug=${slug}`);
                const post = response.data[0];

                if (post.featured_media) {
                    const mediaResponse = await axios.get(`https://emad.team/mag/wp-json/wp/v2/media/${post.featured_media}`);
                    post.featured_media_url = mediaResponse.data.source_url;
                }

                if (post.categories.length > 0) {
                    const categoryNames = await Promise.all(post.categories.map(async (catId) => {
                        const categoryResponse = await axios.get(`https://emad.team/mag/wp-json/wp/v2/categories/${catId}`);
                        return categoryResponse.data.name;
                    }));
                    post.category_names = categoryNames.join(', ');
                }

                setPost(post);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);

    return (
        <div className='page-seven-container'>
            <Helmet>
                <title>{post ? `تیم عماد | ${post.title.rendered}` : "در حال بارگذاری..."}</title>
                <meta name="description" content={post ? post.title.rendered : "در حال بارگذاری توضیحات..."} />
                <meta name="keywords" content="تیم عماد, بلاگ, مقاله" />
            </Helmet>
            <div className='xs-d-none l-d-block'>
                <Header />
            </div>
            <div className='xs-d-block l-d-none'>
                <HeaderBlog />
            </div>
            <div className='space-7-1'></div>
            <div className='xs-d-none l-d-block'>
                {loading ? (
                    <div className='skeleton-wrapper react-loading-skeleton'>
                        <Skeleton height={100} width="100%" />
                    </div>
                ) : (
                    <LoremLine title={post.title.rendered} categories={post.category_names} />
                )}
            </div>
            <div className='space-7-2'></div>
            <div className='container d-flex justify-content-center align-items-center'>
                {loading ? (
                    <div className='skeleton-wrapper react-loading-skeleton'>
                        <Skeleton height={300} width="100%" />
                    </div>
                ) : (
                    <XtraPostCard post={post} />
                )}
            </div>
            <div className='space-7-2'></div>
            <div className='container'>
                <CommentsForm />
            </div>
            <div className='space-7-3'></div>
            <div className='container'>
                {/* <div className='xs-d-none l-d-block'>
                    <RelatedPost />
                </div> */}
                {/* <div className='xs-d-block l-d-none postdb'>
                    <PostD til="full" />
                </div> */}
            </div>
            <div className='space-7-4'></div>
            <div className='xs-d-none l-d-block'>
                <Improve />
            </div>
            <div className='space-7-5'></div>
            <div className='xs-d-none l-d-block'>
                <div className='container'>
                    <MainForm />
                </div>
                <div className='space-7-4'></div>
                <Footer />
            </div>
            <div className='xs-d-block l-d-none'>
                <FooterMob />
            </div>
        </div>
    );
}

export default PageSeven;
