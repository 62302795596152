import "./BannerApp.css";

import bannerApp from "../../Images/bannerApp.png";
import mouse from "../../Images/mouse.png";

import MainBtn from "../../Components/MainBtn/MainBtn";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen";
import SecondBtn from "../../Components/SecondBtn/SecondBtn";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function BannerApp() {
  const [bah, setBah] = useState(0);

  useEffect(() => {
    setBah(window.innerHeight - 131);
  }, []);

  const handleScroll = () => {
    document.getElementById("app").scrollIntoView();
  };

  return (
    <>
      <div className="container-fluid xs-d-none l-d-flex justify-content-between banner-app-container align-items-center">
        <div className="container w-100 d-flex flex-row justify-content-between align-items-center banner-container-inside">
          <div className="banner-app-image-container">
            <img src={bannerApp} />
          </div>
          <div className="banner-app-space"></div>
          <div className="banner-app-title-container d-flex flex-column align-items-end">
            <div className="title-1">
              <p className="c-white bold rtl">برنامه نویس حرفه ای و مدرن</p>
            </div>
            <div className="title-1">
              <p className="c-white bold rtl">اپلیکیشن اندروید و آی او اس</p>
            </div>
            <div className="title-2">
              <p className="c-white rtl">
                <span className="c-main">
                  خدمات حرفه ای طراحی و برنامه نویسی اپلیکیشن
                </span>
              </p>
            </div>
            <div className="title-text">
              <p className="c-white-70 rtl">
                اگر به یک اپلیکیشن حرفه ای برای سیستم عامل های اندروید و آی او
                اس احتیاج دارید میتوانید به ما اعتماد کنید. ما در کمترین زمان
                ممکن اپلیکیشنی با بیشترین کارایی طراحی میکنیم.
              </p>
            </div>
            <div className="d-flex justify-content-end w-100 link-app-container">
              <a href="#app" className="link1">
                <MainBtn text="بیشتر بدانید" />
              </a>
              <div className="banner-btn-space"></div>
              <Link className="xs-d-none l-d-block  link1" to="/service">
                <SecondBtn text="خدمات و سرویس ها" />
              </Link>
            </div>
          </div>
        </div>
        <div className="mouse-app-container d-flex flex-column align-items-center">
          <img className="mb-10 pointer" src={mouse} onClick={handleScroll} />
          <p className="c-white-20 default">اسکرول کنید</p>
        </div>
      </div>

      <div
        style={{ height: bah + "px" }}
        className="position-relative xs-d-flex l-d-none container-fluid banner-mob-container flex-column justify-content-start align-items-center"
      >
        <div className="h-100 flex-fill d-flex flex-column justify-content-start align-items-center banner-app-container-inside">
          <div className="banner-appmob-image-container">
            <img src={bannerApp} />
          </div>
          <div className="banner-design-title-container d-flex flex-column align-items-end">
            <div className="title-1">
              <p className="c-white rtl">برنامه نویس حرفه ای و مدرن</p>
            </div>
            <div className="title-1">
              <p className="c-white bold rtl f-24">
                اپلیکیشن اندروید و آی او اس
              </p>
            </div>
            <div className="title-2">
              <p className="c-white rtl">
                <span className="c-main">
                  خدمات حرفه ای طراحی و برنامه نویسی اپلیکیشن
                </span>
              </p>
            </div>
            <div className="title-text">
              <p className="c-white-70 rtl">
                اگر به یک اپلیکیشن حرفه ای برای سیستم عامل های اندروید و آی او
                اس احتیاج دارید میتوانید به ما اعتماد کنید. ما در کمترین زمان
                ممکن اپلیکیشنی با بیشترین کارایی طراحی میکنیم.
              </p>
            </div>
            <div className="w-100 btn-banner-appmob-container">
              <a className="link1" href="#design">
                <MainBtnOpen text="بیشتر بدانید" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerApp;
