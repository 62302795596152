import { useEffect, useState } from 'react';
import ShopItemCard from '../../Components/ShopItemCard/ShopItemCard';
import { isMobile } from 'react-device-detect';
import HeaderShop from '../HeaderShop/HeaderShop';
import './HostingShop.css';

function HostingShop(props) {
    // const [hostTitle, setHostTitle] = useState(1);
    const [hostTitle, setHostTitle] = useState(isMobile ? 1 : 5); // مقدار پیش‌فرض بر اساس دستگاه


    const hostingData = {
        5: [
            { id: 4, title: 'هاست لینوکس - یاقوت کبود', price: '230,000 تومان', features: ['پهنای باند نامحدود', 'کنترل پنل Cpanel', 'تحویل آنی بله', 'Email نامحدود', 'FTP نامحدود', 'Mysql نامحدود', 'addon domain نامحدود', 'park domain نامحدود', 'ساب دامین نامحدود'], divider: 'فضا 10240 MB', link: 'https://dsh.emad.team/index.php/store/linux-host/blue-sapphire-package' },
            { id: 3, title: 'هاست لینوکس - سرویس یاقوت', price: '130,000 تومان', features: ['پهنای باند نامحدود', 'کنترل پنل Cpanel', 'تحویل آنی بله', 'Email نامحدود', 'FTP نامحدود', 'Mysql نامحدود', 'addon domain نامحدود', 'park domain نامحدود', 'ساب دامین نامحدود'], divider: 'فضا 5210 MB', link: 'https://dsh.emad.team/index.php/store/linux-host/sapphire-package' },
            { id: 2, title: 'هاست لینوکس - سرویس الماس', price: '70,000 تومان', features: ['پهنای باند نامحدود', 'کنترل پنل Cpanel', 'تحویل آنی بله', 'Email نامحدود', 'FTP نامحدود', 'Mysql نامحدود', 'addon domain نامحدود', 'park domain نامحدود', 'ساب دامین نامحدود'], divider: 'فضا 2048 MB', link: 'https://dsh.emad.team/index.php/store/linux-host/diamond-package' },
            { id: 1, title: 'هاست لینوکس - سرویس طلایی', price: '50,000 تومان', features: ['پهنای باند نامحدود', 'کنترل پنل Cpanel', 'تحویل آنی بله', 'Email نامحدود', 'FTP نامحدود', 'Mysql نامحدود', 'addon domain نامحدود', 'park domain نامحدود', 'ساب دامین نامحدود'], divider: 'فضا 1024 MB', link: 'https://dsh.emad.team/index.php/store/linux-host/golden-package' }
        ],
        2: [
            { id: 4, title: 'هاست اختصاصی - De - Ded 30G', price: '690,000 تومان', features: ['فضا ۳۰ گیگ SSD', 'پهنای باند نامحدود', 'CPU 4 Core', 'RAM 8 GB', 'تعداد پروسس Process 100', 'کنترل پنل Cpanel', 'SSL رایگان', 'امکان تغییر نسخه PHP', 'نصب رایگان اسکریپت', 'تعداد ایمیل نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'پارک دامین ۱', 'ساب دومین نامحدود', 'منابع اختصاصی'], divider: 'فضا ۳۰ گیگ SSD', link: 'https://dsh.emad.team/index.php/store/swpr-hst-khtssy-almn/de-ded-30g' },
            { id: 3, title: 'هاست اختصاصی - De - Ded 30G', price: '690,000 تومان', features: ['فضا ۳۰ گیگ SSD', 'پهنای باند نامحدود', 'CPU 4 Core', 'RAM 8 GB', 'تعداد پروسس Process 100', 'کنترل پنل Cpanel', 'SSL رایگان', 'امکان تغییر نسخه PHP', 'نصب رایگان اسکریپت', 'تعداد ایمیل نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'پارک دامین ۱', 'ساب دومین نامحدود', 'منابع اختصاصی'], divider: 'فضا ۳۰ گیگ SSD', link: 'https://dsh.emad.team/index.php/store/swpr-hst-khtssy-almn/de-ded-30g' },
            { id: 2, title: 'هاست اختصاصی - De - Ded 20G', price: '530,000 تومان', features: ['فضا ۲۰ گیگ SSD', 'پهنای باند نامحدود', 'CPU 2 Core', 'RAM 4 GB', 'تعداد پروسس Process 60', 'کنترل پنل Cpanel', 'SSL رایگان', 'امکان تغییر نسخه PHP', 'نصب رایگان اسکریپت', 'تعداد ایمیل نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'پارک دامین ۱', 'ساب دومین نامحدود', 'منابع اختصاصی'], divider: 'فضا ۲۰ گیگ SSD', link: 'https://dsh.emad.team/index.php/store/swpr-hst-khtssy-almn/de-ded-20g' },
            { id: 1, title: 'هاست اختصاصی - De - Ded 10G', price: '400,000 تومان', features: ['فضا ۱۰ گیگ SSD', 'پهنای باند نامحدود', 'CPU 2 Core', 'RAM 2 GB', 'تعداد پروسس Process 40', 'کنترل پنل Cpanel', 'SSL رایگان', 'امکان تغییر نسخه PHP', 'نصب رایگان اسکریپت', 'تعداد ایمیل نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'پارک دامین ۱', 'ساب دومین نامحدود', 'منابع اختصاصی'], divider: 'فضا ۱۰ گیگ SSD', link: 'https://dsh.emad.team/index.php/store/swpr-hst-khtssy-almn/de-ded-10g' }
        ],
        3: [
            { id: 4, title: 'هاست پربازدید - پربازدید ویژه', price: '4,600,000 تومان', features: ['پهنای باند نامحدود', 'اکانت ایمیل نامحدود', 'تعداد دامنه نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'SSL رایگان', 'امکان تغییر نسخه php', 'هارد پرسرعت SSD-NvMe', 'تحویل آنی'], divider: 'فضا 10000 مگابایت', link: 'https://dsh.emad.team/index.php/store/pro/s5-prbzdyd-wyjh' },
            { id: 3, title: 'هاست پربازدید - سرویس الماس', price: '260,000 تومان', features: ['پهنای باند نامحدود', 'اکانت ایمیل نامحدود', 'تعداد دامنه نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'SSL رایگان', 'امکان تغییر نسخه php', 'هارد پرسرعت SSD-NvMe', 'تحویل آنی'], divider: 'فضا 5000 مگابایت', link: 'https://dsh.emad.team/index.php/store/pro/s4-prbzdyd-lms' },
            { id: 2, title: 'هاست پربازدید - سرویس طلایی', price: '200,000 تومان', features: ['پهنای باند نامحدود', 'اکانت ایمیل نامحدود', 'تعداد دامنه نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'SSL رایگان', 'امکان تغییر نسخه php', 'هارد پرسرعت SSD-NvMe', 'تحویل آنی'], divider: 'فضا 2000 مگابایت', link: 'https://dsh.emad.team/index.php/store/pro/s3-prbzdyd-tlyy' },
            { id: 1, title: 'هاست پربازدید - سرویس نقره ای', price: '100,000 تومان', features: ['پهنای باند نامحدود', 'اکانت ایمیل نامحدود', 'تعداد دامنه نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'SSL رایگان', 'امکان تغییر نسخه php', 'هارد پرسرعت SSD-NvMe', 'تحویل آنی'], divider: 'فضا 1000 مگابایت', link: 'https://dsh.emad.team/index.php/store/pro/s2-prbzdyd-nqrh-y' }
        ],
        4: [
            { id: 4, title: 'هاست وردپرس - یاقوت کبود', price: '460,000 تومان', features: ['10000 مگابایت فضا', 'پهنای باند نامحدود', 'اکانت ایمیل نامحدود', 'تعداد دامنه نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'کنترل پنل CPanel', 'SSL رایگان', 'نصب کننده اتوماتیک وردپرس', 'امکان تغییر نسخه php', 'مناسب برای سایت پربازدید', 'هارد پرسرعت SSD-NvMe'], divider: '10000 مگابایت فضا', link: 'https://dsh.emad.team/index.php/store/de-wp/hst-wrdprs-yqwt-khbwd' },
            { id: 3, title: 'هاست وردپرس - سرویس یاقوت', price: '260,000 تومان', features: ['5000 مگابایت فضا', 'پهنای باند نامحدود', 'اکانت ایمیل نامحدود', 'تعداد دامنه نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'کنترل پنل CPanel', 'SSL رایگان', 'نصب کننده اتوماتیک وردپرس', 'امکان تغییر نسخه php', 'مناسب برای سایت پربازدید', 'هارد پرسرعت SSD-NvMe'], divider: '5000 مگابایت فضا', link: 'https://dsh.emad.team/index.php/store/de-wp/hst-wrdprs-yqwt' },
            { id: 2, title: 'هاست وردپرس - سرویس الماس', price: '140,000 تومان', features: ['2000 مگابایت فضا', 'پهنای باند نامحدود', 'اکانت ایمیل نامحدود', 'تعداد دامنه نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'کنترل پنل CPanel', 'SSL رایگان', 'نصب کننده اتوماتیک وردپرس', 'امکان تغییر نسخه php', 'مناسب برای سایت پربازدید', 'هارد پرسرعت SSD-NvMe'], divider: '2000 مگابایت فضا', link: 'https://dsh.emad.team/index.php/store/de-wp/hst-wrdprs-lms' },
            { id: 1, title: 'هاست وردپرس - سرویس طلایی', price: '100,000 تومان', features: ['1000 مگابایت فضا', 'پهنای باند نامحدود', 'اکانت ایمیل نامحدود', 'تعداد دامنه نامحدود', 'تعداد دیتابیس نامحدود', 'تعداد FTP نامحدود', 'کنترل پنل CPanel', 'SSL رایگان', 'نصب کننده اتوماتیک وردپرس', 'امکان تغییر نسخه php', 'مناسب برای سایت پربازدید', 'هارد پرسرعت SSD-NvMe'], divider: '1000 مگابایت فضا', link: 'https://dsh.emad.team/index.php/store/de-wp/hst-tlyy' }
        ],
        1: [
            { id: 4, title: 'هاست شخصی - شخصی ویژه', price: '240,000 تومان', features: ['10000 مگابایت فضا', 'پهنای باند نامحدود', 'دیگر امکانات نامحدود', 'SSL رایگان', 'امکان تغییر نسخه php', 'دامنه IR رایگان', 'هارد پرسرعت SSD-NvMe', 'کنترل پنل CPanel', 'تحویل آنی'], divider: '10000 مگابایت فضا', link: 'https://dsh.emad.team/index.php/store/personal/special-personal-service' },
            { id: 3, title: 'هاست شخصی - شخصی الماس', price: '140,000 تومان', features: ['5000 مگابایت فضا', 'پهنای باند نامحدود', 'دیگر امکانات نامحدود', 'SSL رایگان', 'امکان تغییر نسخه php', 'دامنه IR رایگان', 'هارد پرسرعت SSD-NvMe', 'کنترل پنل CPanel', 'تحویل آنی'], divider: '5000 مگابایت فضا', link: 'https://dsh.emad.team/index.php/store/personal/diamond-personal-service' },
            { id: 2, title: 'هاست شخصی - شخصی طلایی', price: '80,000 تومان', features: ['2000 مگابایت فضا', 'پهنای باند نامحدود', 'دیگر امکانات نامحدود', 'SSL رایگان', 'امکان تغییر نسخه php', 'دامنه IR رایگان', 'هارد پرسرعت SSD-NvMe', 'کنترل پنل CPanel', 'تحویل آنی'], divider: '2000 مگابایت فضا', link: 'https://dsh.emad.team/index.php/store/personal/golden-personal-service' },
            { id: 1, title: 'هاست شخصی - شخصی نقره ای', price: '60,000 تومان', features: ['1000 مگابایت فضا', 'پهنای باند نامحدود', 'دیگر امکانات نامحدود', 'SSL رایگان', 'امکان تغییر نسخه php', 'دامنه IR رایگان', 'هارد پرسرعت SSD-NvMe', 'کنترل پنل CPanel', 'تحویل آنی'], divider: '1000 مگابایت فضا', link: 'https://dsh.emad.team/index.php/store/personal/silver-personal-service' }
        ],
    };

    const style = (id) => {
        document.getElementById('i1').classList.remove("shop-btn-active");
        document.getElementById('i2').classList.remove("shop-btn-active");
        document.getElementById('i3').classList.remove("shop-btn-active");
        document.getElementById('i4').classList.remove("shop-btn-active");
        document.getElementById('i5').classList.remove("shop-btn-active");
        document.getElementById('i1').classList.add("shop-btn");
        document.getElementById('i2').classList.add("shop-btn");
        document.getElementById('i3').classList.add("shop-btn");
        document.getElementById('i4').classList.add("shop-btn");
        document.getElementById('i5').classList.add("shop-btn");
        document.getElementById(id).classList.add("shop-btn-active");
        document.getElementById(id).classList.remove("shop-btn");
    }

    useEffect(
        () => {
            if (props.hn === 'true') {
                document.getElementById('hn').style.display = 'none';
            }
            if (props.br === 'true') {
                document.getElementById('cards2').classList.add('dni');
            }
        }, [props.hn, props.br]
    );

    return (
        <>
            <div id="hn">
                <div className='xs-d-block l-d-none'>
                    <HeaderShop />
                    <div className='space-shop-header'></div>
                </div>
            </div>
            <div className='hosting-shop-container'>
                <div className='d-flex xs-mb-40 lg-mb-80 jus scroll-container'>
                    {Object.keys(hostingData).map((key) => (
                        <div
                            key={key}
                            id={`i${key}`}
                            onClick={() => { setHostTitle(parseInt(key)); style(`i${key}`); }}
                            className={`pointer shop-btn ${parseInt(key) === hostTitle ? 'shop-btn-active' : ''}`}
                        >
                            <p>{hostingData[key][0].title.split(' - ')[0]}</p>
                        </div>
                    ))}
                </div>
                <div className='container-xl shop-card-con w-100 over-content d-flex flex-row flex-lg-column w-100'>
                    <div className='xs-d-none l-d-flex w-100 d-flex xs-mb-0 l-mb-80 justify-content-between'>
                        {hostingData[hostTitle].map((host) => (
                            <ShopItemCard key={host.id} host={host} />
                        ))}
                    </div>
                    <div id="cards2" className='scroll-container xs-d-none l-d-flex w-100 justify-content-between'>
                        {hostingData[hostTitle].map((host) => (
                            <ShopItemCard key={host.id} host={host} className="shop-item-card" />
                        ))}
                    </div>
                    <div className='w-100 over-content xs-d-flex flex-row-reverse l-d-none'>
                        {hostingData[hostTitle].map((host) => (
                            <div key={host.id} className='flex-fill shop-card-space'>
                                <ShopItemCard host={host} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='div-shrinker-3'></div>
        </>
    );
}

export default HostingShop;
