import "./MainBtn.css";
import arrowLeft from "../../Images/arrowleft.png";
import { Link } from "react-router-dom";

function MainBtn(props) {
  const { className } = props;
  return (
    <div className={`mainbtn-container ${className}`}>
      <button className="bg-main">
        <span>
          <img
            className="mainbtn-icon"
            src={arrowLeft}
            alt="right to left arrow icon"
          />
        </span>
        <span className="mainbtn-text c-white">{props.text}</span>
      </button>
    </div>
  );
}

export default MainBtn;
