import "./Improve.css";
import MainBtnWhite from "../../Components/MainBtnWhite/MainBtnWhite.js";
import SecondBtn from "../../Components/SecondBtn/SecondBtn.js";
import dotted from "../../Images/dotted.png";
import { Link } from "react-router-dom";

function Improve() {
  return (
    <div className="position-relative container-fluid d-flex justify-content-center">
      <div className="position-relative improve-container xs-px-20 l-px-60 xs-w-100 m-w-85 d-flex flex-column-reverse justify-content-center align-items-center flex-md-row justify-content-md-between bg-main">
        <div className="d-flex justify-content-start xs-mr-10 l-mr-0">
          <div className="inner-margin">
            <Link to="/contact">
              <MainBtnWhite text="تماس با ما" />
            </Link>
          </div>
          <div>
            <SecondBtn text="چت آنلاین" />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center align-items-md-end text-align-right">
          <div className="xs-mb-8 m-mb-0 imporve-text-one c-white bold">
            <p>
              <span>!</span>پیشرفت را در کسب و کار خود حس کنید
            </p>
          </div>
          <div className="rtl xs-mb-20 m-mb-0 improve-text-two c-white">
            <p>
              در هر ضمینه ای نیاز به مشاوره دارید اصلا نگران نباشید با ایجاد
              تماس ، بدون هزینه با ما ابهامات خود را رفع کنید
            </p>
          </div>
        </div>
        <img className="dotted-improve-1" src={dotted} />
      </div>
      <img className="dotted-improve-2" src={dotted} />
    </div>
  );
}

export default Improve;
