import React, { useState } from 'react';
import axios from 'axios';
import './Form2.css';
import MainBtnOpen from '../MainBtnOpen/MainBtnOpen.js';
import facebookMain from '../../Images/facebook-main.png';
import whatsappMain from '../../Images/whatsapp-main.png';
import instagramMain from '../../Images/instagram-main.png';
import telegramMain from '../../Images/telegram-main.png';
import twitterMain from '../../Images/twitter-main.png';

function Form2() {
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [statusType, setStatusType] = useState(''); // برای تعیین نوع پیام (موفقیت یا خطا)

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!phone || !name || !message) {
            setStatus('All fields are required');
            setStatusType('error');
            return;
        }

        if (phone.length !== 11 || !/^\d+$/.test(phone)) {
            setStatus('شماره موبایل خود را به صورت انگلیسی و همراه با ۰ وارد نمایید');
            setStatusType('error');
            return;
        }

        try {
            await axios.post('https://emad.team/send-email', {
                phone,
                name,
                message
            });

            setStatus('پیام با موفقیت ارسال شد');
            setStatusType('success');
        } catch (error) {
            console.error('Error sending message:', error);
            setStatus('چه عجیب ! خطا خورد . دوباره امتحان کنید');
            setStatusType('error');
        }
    };

    return (
        <form className='form-2-container d-flex flex-column' onSubmit={handleSubmit}>
            <div className='d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between w-100'>
                <div className='form-2-input-container'>
                    <input 
                        type='text'
                        className='c-white rtl w-100' 
                        placeholder='شماره تلفن' 
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        pattern="\d*"
                        maxLength="11"
                        required
                    />
                </div>
                <div className='form-2-input-container form-2-input-2'>
                    <input 
                        type='text'
                        className='c-white rtl w-100' 
                        placeholder='نام و نام خانوادگی' 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
            </div>
            <div className='w-100 form-2-textarea-container'>
                <textarea 
                    className='c-white rtl w-100' 
                    placeholder='پیام خود را وارد کنید'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                ></textarea>
            </div>
            <div className='w-100 d-flex flex-column flex-lg-row justify-content-center justify-content-lg بین align-items-center'>
                <div className='w-100'>
                    <MainBtnOpen type="submit" className="main-btn-open" text="ارسال پیام" />
                </div>
                <div className='d-flex justify-content-center justify-content-lg-between align-items-center form-2-icon-container'>
                    <img className="pointer" src={facebookMain} alt="Facebook"/>
                    <img className="pointer" src={whatsappMain} alt="WhatsApp"/>
                    <img className="pointer" src={twitterMain} alt="Twitter"/>
                    <img className="pointer" src={instagramMain} alt="Instagram"/>
                    <img className="pointer" src={telegramMain} alt="Telegram"/>
                </div>
            </div>
            {status && <p className={`status-message ${statusType}`}>{status}</p>}
        </form>
    );
}

export default Form2;
