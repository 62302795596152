import "./BannerDesign.css";
import bannerDesign from "../../Images/bannerDesign.png";
import MainBtn from "../../Components/MainBtn/MainBtn.js";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen.js";
import SecondBtn from "../../Components/SecondBtn/SecondBtn.js";
import mouse from "../../Images/mouse.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function BannerDesign() {
  const [bdh, setBdh] = useState(0);

  useEffect(() => {
    setBdh(window.innerHeight - 131);
  }, []);

  const handleScroll = () => {
    document.getElementById("design").scrollIntoView();
  };

  return (
    <>
      <div className="xs-d-none l-d-flex container-fluid banner-des-container flex-column justify-content-between align-items-center">
        <div className=" container h-100 d-flex flex-column flex-lg-row-reverse justify-content-evenly justify-content-lg-between align-items-end align-items-lg-center w-100 banner-container-inside">
          <div className="banner-design-image-container">
            <img src={bannerDesign} />
          </div>
          <div className="flex-fill banner-design-title-container d-flex flex-column align-items-end">
            <div className="title-1">
              <p className="c-white bold rtl">لذت اوج زیبایی و ظرافت</p>
            </div>
            <div className="title-1">
              <p className="c-white bold rtl">گرافیک و طراحی مدرن</p>
            </div>
            <div className="title-2">
              <p className="c-white rtl">
                <span className="c-main">
                  {" "}
                  انواع خدمات حرفه ای طراحی گرافیک
                </span>
              </p>
            </div>
            <div className="title-text">
              <p className="c-white-70 rtl">
                هر کسب و کار حرفه ای به طرح های گرافیکی اختصاصی احتیاج دارد. اگر
                شما نیز به فکر سفارش طرح های گرافیکی مانند لوگو و یا تصاویر
                تبلیغاتی هستید، شرکت عماد با سال ها تجربه در زمینه طراحی گرافیک،
                میتواند یکی از بهترین انتخاب های شما باشد.
              </p>
            </div>
            <div className="xs-mt-20 l-mt-0 align-self-end d-flex justify-content-end w-100 link-design-container">
              <a className="link1" href="#design">
                <MainBtn text="بیشتر بدانید" />
              </a>
              <div className="l-btn"></div>

              <Link className="xs-d-none l-d-block link1" to="/service">
                <SecondBtn text="خدمات و سرویس ها" />
              </Link>
            </div>
          </div>
        </div>
        <div className="xs-d-none l-d-flex mouse-container flex-column justify-content-center align-items-center">
          <img className="mb-10 pointer" src={mouse} onClick={handleScroll} />
          <p className="c-white-20 default">اسکرول کنید</p>
        </div>
      </div>

      <div className="xs-d-flex l-d-none container-fluid banner-mob-container flex-column justify-content-start align-items-center">
        <div className="h-100 flex-fill d-flex flex-column justify-content-start align-items-center banner-container-inside">
          <div className="banner-mob-image-container mb-20">
            <img src={bannerDesign} />
          </div>
          <div className="banner-design-title-container d-flex flex-column align-items-end">
            <div className="title-1">
              <p className="c-white rtl">لذت اوج زیبایی و ظرافت</p>
            </div>
            <div className="title-1">
              <p className="c-white rtl">گرافیک و طراحی مدرن</p>
            </div>
            <div className="title-2">
              <p className="c-white rtl">
                <span className="c-main">
                  {" "}
                  انواع خدمات حرفه ای طراحی گرافیک
                </span>
              </p>
            </div>
            <div className="title-text">
              <p className="c-white-70 rtl">
                هر کسب و کار حرفه ای به طرح های گرافیکی اختصاصی احتیاج دارد. اگر
                شما نیز به فکر سفارش طرح های گرافیکی مانند لوگو و یا تصاویر
                تبلیغاتی هستید، تیم عماد با سال ها تجربه در زمینه طراحی گرافیک،
                میتواند یکی از بهترین انتخاب های شما باشد.
              </p>
            </div>
            <div className="w-100 btn-banner-container">
              <a className="link1" href="#design">
                <MainBtnOpen text="بیشتر بدانید" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerDesign;
