import './HeaderService.css'
import about from '../../Images/service.png';
import leftarrow from '../../Images/leftarrow.png';
import {Link} from 'react-router-dom';

function HeaderService () {
    return (
        <div className='container header-contact d-flex justify-content-between align-items-center'>
            <div className='header-contact-left'>
                <Link className='link1' to="/"><img src={leftarrow} /></Link>
            </div>
            <div className='d-flex'>
                <p className='header-contact-text'>خدمات</p>
                <img className='d-block header-contact-icon' src={about} />
            </div>
        </div>
    );
}

export default HeaderService;