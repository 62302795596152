import './ScrollTwo.css';
import arrowleft from '../../Images/arrowleft.png';
import personal from '../../Images/personal.png';
import follow from '../../Images/follow.png';
import right from '../../Images/ar.png';
import left from '../../Images/al.png';
import {useState, useEffect} from 'react';

function ScrollTwo(props){

    const [ranger, setRanger] = useState(0);
    const [maxer, setMaxer] = useState(0);

    const changeri = (inner) => {
        setRanger(inner);
    }

    const scrolled = () => {
        let scrollpos = document.getElementById(props.id).scrollLeft;
        setRanger(scrollpos);
    }

    const arrowor = (point) => {
        if(point > 0) {
                if(ranger + 100 <= maxer) {
                    setRanger(ranger+100);
                }
        }
        if(point < 0) {
                if(ranger - 100 >= 0) {
                    setRanger(ranger-100);
                }
        }

    }

    useEffect(()=>{
        document.getElementById(props.id).scrollLeft= ranger;
        let childer =document.getElementById(props.id).getElementsByClassName('card-scoroll').length;
        setMaxer(childer*100);
    },[ranger]);

    return (
    <>  
        <div className='container d-flex justify-content-between align-items-center'>
            <div className="mb-70 d-flex justify-content-start align-items-center">
                <div>
                    <img onMouseDown={()=>{arrowor(-1)}} className="pointer range-arrow" src={left} />
                </div>
                <div className="slidecontainer mx-20">
                    <input onChange={(e) => {changeri(parseInt(e.target.value))}} type="range" min="1" max={maxer} value={ranger} className="slider" id="myRange" />
                </div>
                <div>
                    <img onMouseDown={()=>{arrowor(+1)}}  className="pointer range-arrow" src={right}/>
                </div>
            </div>
            <div className='position-relative mb-40'>
                <p className='rtl c-white bold scroll-one-header-fa'>
                    محبوب ترین خدمات
                </p>
                <p className="orange-box position-absolute scroll-orange-box z-n-1"></p>
                <p className='rtl c-white-70 scroll-header-en'>
                    POPULAR SERVICES
                </p>
            </div>
        </div>
        <div onScroll={scrolled} id={props.id} className='container frol scroll-one-container d-flex justify-content-center'>
            <div className="ml-im-80">
                <div  className="card-scoroll d-flex flex-column">
                    <div className="img-bg justify-content-center align-items-center d-flex">
                        <img className="icon-social" src={follow} alt=""/>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center txt-card">
                        <div>
                            <img className="arrow" src={arrowleft} alt=""/>
                        </div>
                        <div className="mtn-footer rtl d-flex flex-column justify-content-center align-items-start">
                            <p className="c-white"><span>10</span> هزار فالوور ایرانی واقعی</p>
                            <p className="rtl"> 850,000 <span>تومان</span></p>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="ml-im-80">
                <div className="card-scoroll d-flex flex-column">
                    <div className="img-bg justify-content-center align-items-center d-flex">
                        <img className="icon-social" src={follow} alt=""/>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center txt-card">
                        <div>
                            <img className="arrow" src={arrowleft} alt=""/>
                        </div>
                        <div className="mtn-footer rtl d-flex flex-column justify-content-center align-items-start">
                            <p className="c-white"><span>10</span> هزار فالوور ایرانی واقعی</p>
                            <p className="rtl"> 850,000 <span>تومان</span></p>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="ml-im-80">
                <div className="card-scoroll d-flex flex-column">
                    <div className="img-bg justify-content-center align-items-center d-flex">
                        <img className="icon-social" src={follow} alt=""/>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center txt-card">
                        <div>
                            <img className="arrow" src={arrowleft} alt=""/>
                        </div>
                        <div className="mtn-footer rtl d-flex flex-column justify-content-center align-items-start">
                            <p className="c-white"><span>10</span> هزار فالوور ایرانی واقعی</p>
                            <p className="rtl"> 850,000 <span>تومان</span></p>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="ml-im-80">
                <div className="card-scoroll d-flex flex-column">
                    <div className="img-bg justify-content-center align-items-center d-flex">
                        <img className="icon-social" src={follow} alt=""/>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center txt-card">
                        <div>
                            <img className="arrow" src={arrowleft} alt=""/>
                        </div>
                        <div className="mtn-footer rtl d-flex flex-column justify-content-center align-items-start">
                            <p className="c-white"><span>10</span> هزار فالوور ایرانی واقعی</p>
                            <p className="rtl"> 850,000 <span>تومان</span></p>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="ml-im-80">
                <div className="card-scoroll d-flex flex-column">
                    <div className="img-bg justify-content-center align-items-center d-flex">
                        <img className="icon-social" src={follow} alt=""/>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center txt-card">
                        <div>
                            <img className="arrow" src={arrowleft} alt=""/>
                        </div>
                        <div className="mtn-footer rtl d-flex flex-column justify-content-center align-items-start">
                            <p className="c-white"><span>10</span> هزار فالوور ایرانی واقعی</p>
                            <p className="rtl"> 850,000 <span>تومان</span></p>
                        </div>
                    </div>
                </div>  
            </div>
            
        </div>
    </>
    );
}

export default ScrollTwo;