// css
import './Footer.css';
// images
import arrowup from '../../Images/arrowup.png';
import logo from '../../Images/logo.png';
import twitter from '../../Images/twitter.png';
import facebook from '../../Images/facebook.png';
import instagram from '../../Images/instagram.png';
import telegram from '../../Images/telegram.png';
import linkdin from '../../Images/linkdin.png';
import { CompanyName } from '../../constants';
import enamad from '../../Images/enamad.png';
// react
import { Link } from "react-router-dom";

function Footer() {

    const toup = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <>
            <div className='container-fluid footer-container xs-pt-30 m-pt-100 xs-mr-30 m-mr-0'>
                <div className='container'>
                    <div className="d-flex flex-column justify-content-center align-items-between">
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-center justify-content-md-between align-items-end align-items-md-start footer-top">
                            <div className="d-flex flex-column justify-content-between align-items-end lonebox">
                                <div className="footer-header-container position-relative">
                                    <p className="c-white footer-header position-absolute z-n-3">نماد اعتماد <span className="main-box">&nbsp;</span></p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center footer-box w-100">
                                    <div className="footer-box-item">
                                        {/* <p className="c-white-70">جایگاه</p> */}
                                        <a referrerPolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=509461&Code=16mcdGHTfn91cCKMuUVki9rYrKDoptBB'>
                                            <img referrerPolicy='origin' src={enamad} alt='' style={{ cursor: 'pointer' }} code='16mcdGHTfn91cCKMuUVki9rYrKDoptBB' />
                                        </a>
                                    </div>
                                    {/* <div className="footer-box-item">
                                        <p className="c-white-70">جایگاه</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className='xs-mb-60 m-mb-0 w-100 d-flex justify-content-end justify-content-md-evenly flex-fill'>
                                <div className="xs-mr-80 m-mr-0  d-flex flex-column justify-content-between align-items-end">
                                    <div className="footer-header-container position-relative">
                                        <p className="c-white footer-header position-absolute z-n-2">لینک های مفید <span className="main-box">&nbsp;</span></p>
                                    </div>
                                    <div className="d-flex flex-column justify-content-between align-items-end footer-list">
                                        <div>
                                            <a className="link1" href="https://dsh.emad.team/cart.php?a=add&domain=register" target='_blank'>
                                                <p className="c-white-80 ll">ثبت دامنه</p>
                                            </a>
                                        </div>
                                        <div>
                                            <Link className="link1" to="/contact">
                                                <p className="c-white-80 ll">مشاوره رایگان</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <a className="link1" href="https://dsh.emad.team/index.php/store/ir-wp" target='_blank'>
                                                <p className="c-white-80 ll">هاست وردپرس</p>
                                            </a>
                                        </div>
                                        <div>
                                            <Link className='link1' to="/FAQs">
                                                <p className="c-white-80 ll">سوالات متداول</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column justify-content-between align-items-end">
                                    <div className="footer-header-container position-relative">
                                        <p className="c-white footer-header position-absolute z-n-2">صفحات پر بازدید<span className="main-box">&nbsp;</span></p>
                                    </div>
                                    <div className="d-flex flex-column justify-content-between align-items-end footer-list">
                                        <div>
                                            <Link className="link1" to="/">
                                                <p className="c-white-80 ll">صفحه ی اصلی</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link className="link1" to="/contact">
                                                <p className="c-white-80 ll">تماس با ما</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link className="link1" to="/about">
                                                <p className="c-white-80 ll">درباره ما</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link className="link1" to="/signup">
                                                <p className="c-white-80 ll">عضویت</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="xs-mb-60 m-mb-0 xs-w-100 s-w-270 d-flex flex-column justify-content-between align-items-end ronebox">
                                <div>
                                    <img className="footer-logo" src={logo} />
                                </div>
                                <div className="footer-text-containe w-100">
                                    <p className="c-white-80 footer-text text-align-justify w-100 rtl lh-lg">
                                        {CompanyName} ،
                                        با افتخار از سال ۹۳ در ضمینه طراحی سایت ، هاستینگ و سایر خدمات تحت وب در حال  ارائه  خدمات  می باشد
                                    </p>
                                </div>
                                {/* <div className="w-100 d-flex justify-content-between align-items-center w-100">
                                    <div className="footer-icons-container d-flex justify-content-center align-items-center bg-icons">
                                        <img className="footer-icons" src={linkdin} />
                                    </div>
                                    <div className="footer-icons-container d-flex justify-content-center align-items-center bg-icons">
                                        <img className="footer-icons facebook" src={facebook} />
                                    </div>
                                    <div className="footer-icons-container d-flex justify-content-center align-items-center bg-icons">
                                        <img className="footer-icons" src={instagram} />
                                    </div>
                                    <div className="footer-icons-container d-flex justify-content-center align-items-center bg-icons">
                                        <img className="footer-icons" src={telegram} />
                                    </div>
                                    <div className="footer-icons-container d-flex justify-content-center align-items-center bg-icons">
                                        <img className="footer-icons" src={twitter} />
                                    </div>
                                </div> */}
                                <div className="w-100 d-flex justify-content-center align-items-center"></div>
                            </div>
                        </div>
                        <div className="d-flex flex-row-reverse justify-content-between align-items-center footer-down">
                            <div>
                                <p className="copyright c-white">
                                    تمام حقوق برای
                                    <span className="emad" > &nbsp; {CompanyName} &nbsp; </span>
                                    محفوض است</p>
                            </div>
                            <div onClick={toup} className="bg-main arrowup-container d-flex justify-content-center align-items-center pointer">
                                <img className="arrowup" src={arrowup} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="flex-row-reverse justify-content-between align-items-center footer-down-2">
                <div>
                    <p className="copyright c-white">
                        تمام حقوق برای
                        <span className="emad" > {CompanyName} </span>
                        محفوض است</p>
                </div>
                <div onClick={toup} className="bg-main arrowup-container d-flex justify-content-center align-items-center pointer">
                    <img className="arrowup" src={arrowup} />
                </div>
            </div>
        </>
    );
}

export default Footer;
