import "./DropQuestion.css";
import arrowDown from "../../Images/arrowdown.png";
import { useState } from "react";

function DropQuestion(props) {
  const { title, answer } = props;
  const [open, setOpen] = useState(false);

  const quesOpen = () => {
    if (!open) {
      setOpen(true);
      document
        .getElementById(props.id)
        .classList.add("drop-question-container-open");
      document
        .getElementById(props.id)
        .classList.remove("drop-question-container");
    } else {
      setOpen(false);
      document
        .getElementById(props.id)
        .classList.remove("drop-question-container-open");
      document
        .getElementById(props.id)
        .classList.add("drop-question-container");
    }
  };

  return (
    <div id={props.id} className="drop-question-container">
      <div
        onClick={quesOpen}
        className="drop-question-header d-flex justify-content-between align-items-center"
      >
        <div className="drop-question-icon">
          <img src={arrowDown} />
        </div>
        <div className="c-white f-14 bold">{title}</div>
      </div>
      <div className="drop-question-content">
        <p className="c-white-80 f-12 rtl">{answer}</p>
      </div>
    </div>
  );
}

export default DropQuestion;
