import "./HostingView.css";
import HostingServiceCard from "../../Components/HostingServiceCard/HostingServiceCard";
import factors from "../../Images/factors.png";
import server from "../../Images/server.png";
import tick from "../../Images/tick.png";
import HostingShop from "../../sections/HostingShop/HostingShop";
import search from "../../Images/Search.png";
import arrowDown from "../../Images/arrowdown.png";
import Com from "../../Components/DomainPriceList/Com";
import Net from "../../Components/DomainPriceList/Net";
import Org from "../../Components/DomainPriceList/Org";
import Info from "../../Components/DomainPriceList/Info";
import Online from "../../Components/DomainPriceList/Online";
import Store from "../../Components/DomainPriceList/Store";
import Site from "../../Components/DomainPriceList/Site";
import Blog from "../../Components/DomainPriceList/Blog";
import Improve from "../../sections/Improve/Improve.js";
import MainForm from "../../sections/MainForm/MainForm.js";
import Footer from "../../sections/Footer/Footer.js";
import Header from "../../sections/Header/Header.js";
import BannerHosting from "../../sections/BannerHosting/BannerHosting";
import { useState, useEffect } from "react";
import HeaderHosting from "../../sections/HeaderHosting/HeaderHosting";
import FooterMob from "../../sections/FooterMob/FooterMob";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen";
import ar from "../../Images/ar.png";
import al from "../../Images/al.png";
import dot from "../../Images/dotted.png";
import arr from "../../Images/right.png";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function HostingView() {
  const [ddot, setDdot] = useState(false);
  const [com, setCom] = useState("com");
  const [domainName, setDomainName] = useState('');

  const handleSearch = () => {
    const url = `https://dsh.emad.team/cart.php?a=add&domain=register&query=${domainName}.${com}`;
    window.open(url, '_blank');
  };

  const dropOpener = () => {
    if (!ddot) {
      document.getElementById("ddot3").classList.add("droper-open");
      document.getElementById("ddot3").classList.remove("droper-close");
      document.getElementById("ddot2").classList.add("droper-open");
      document.getElementById("ddot2").classList.remove("droper-close");
      setDdot(true);
    } else {
      document.getElementById("ddot3").classList.add("droper-close");
      document.getElementById("ddot3").classList.remove("droper-open");
      document.getElementById("ddot2").classList.add("droper-close");
      document.getElementById("ddot2").classList.remove("droper-open");
      setDdot(false);
    }
  };

  const setcom = (content) => {
    setCom(content);
  };

  const [slideIndexer, setSlideIndexer] = useState(1);

  const plusSlideser = (n) => {
    setSlideIndexer(slideIndexer + n);
    showSlideser(slideIndexer);
  };

  const currentSlideer = (n) => {
    setSlideIndexer(n);
    showSlideser(slideIndexer);
  };

  const showSlideser = (n) => {
    let i;
    let sli = slideIndexer;
    let slideser = document.getElementsByClassName("mySlideser");
    let dotser = document.getElementsByClassName("doter");
    if (n > slideser.length) {
      setSlideIndexer(1);
      sli = 1;
    }
    if (n < 1) {
      setSlideIndexer(slideser.length);
      sli = slideser.length - 1;
    }
    for (i = 0; i < slideser.length; i++) {
      slideser[i].style.display = "none";
    }
    for (i = 0; i < dotser.length; i++) {
      dotser[i].classList.remove("activeer");
    }
    slideser[sli - 1].style.display = "flex";
    dotser[sli - 1].classList.add("activeer");
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    showSlideser(slideIndexer);
  }, []);

  return (
    <div className="hosting-view-container">
      <Helmet>
        <title>تیم عماد | خدمات هاستینگ و فضای ابری</title>
        <meta name="description" content="صفحه خدمات هاستینگ و فضای ابری تیم عماد. ارائه دهنده هاست های حرفه ای و پرسرعت." />
        <meta name="keywords" content="تیم عماد, هاستینگ, فضای ابری, خدمات هاستینگ,دامنه,ثبت دامنه,انتقال دامنه,هاست اختصاصی,هاست وردپرس,هاست لینوکس" />
      </Helmet>
      <div className="xs-d-none l-d-block">
        <Header />
        <div className="mb-40"></div>
      </div>
      <div className="xs-d-block l-d-none">
        <HeaderHosting />
      </div>
      <div className="xs-d-none l-d-block">
        <BannerHosting />
      </div>
      <div className="space-view"></div>
      <div id="hosting" className="xs-d-none l-d-block container mb-150-i">
        <div className="hosting-view-icon w-100 mb-45 d-flex flex-column align-items-center">
          <img src={factors} />
          <div>
            <p className="mt-16 mb-2 c-white bold f-30 text-align-center">
              خدمات هاستینگ و فضای ابری
            </p>
            <p className="f-16 c-white text-align-center">
              HOSTING AND CLOUD SPACE SERVCIS
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <HostingServiceCard
            title="سوپر هاست اختصاصی"
            caption="و اما شاهکار ما ، هاست های اختصاصی تیم عماد ، منابع بالا و اختصاصی را برای وب‌سایت‌های پرمصرف و پربازدید خود در اختیار داشته باشید. این سرویس ویژه، بهترین عملکرد و پایداری را برای سایت شما تضمین می‌کند. اگر به دنبال میزبانی بی‌نقص برای وب‌سایت خود هستید، هاست اختصاصی انتخابی ایده‌آل است."
            id="web1"
          />
          <HostingServiceCard
            title="هاست وردپرس"
            caption="هاست های وردپرسی ما ، سایت شما را با سرعت و پایداری بی‌نظیری اجرا می کند . جدیدترین سخت‌افزارها ، هارد های SSD و NVMe و وب‌سرور LiteSpeed ، بهترین عملکرد را برای سایت‌های وردپرسی شما تضمین می‌کنند . از این سرویس نگذرید ."
            id="web2"
          />
          <HostingServiceCard
            title="هاست پربازدید"
            caption="اگر به دنبال هاستی برای سایت‌های پربازدید و پرمصرف خود هستید، هاست‌های پربازدید ما بهترین انتخاب برای شما هستند. با پهنای باند نامحدود و دسترسی به درصد بیشتری از منابع سی پی یو و رم، این هاست‌ها آماده‌اند تا میزبانی سایت‌های شما را به بهترین شکل ممکن انجام دهند."
            id="web3"
          />
        </div>
        {/* <div className="w-100 d-flex justify-content-center align-items-center mt-100">
          <div className="d-flex justify-content-start align-items-center">
            <div>
              <img className="pointer range-arrow" src={al} />
            </div>
            <div className="slidecontainer mx-20">
              <input
                type="range"
                min="1"
                max="100"
                value="0"
                className="slider"
                id="myRange"
              />
            </div>
            <div>
              <img className="pointer range-arrow" src={ar} />
            </div>
          </div>
        </div> */}
      </div>
      <div className="xs-d-block l-d-none">
        <div className="container slideshow-containerer wd-flex flex-column w-100 c-white info-container off">
          <div className="mySlideser fadeer l-d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <HostingServiceCard
              title="هاست حرفه‌ای"
              caption="هاست لینوکس حرفه ای یا ویژه ، برای کسب و کارهایی مناسب است که منابع هاست لینوکس معمولی برایشان کافی نبوده و به منابع قوی تری نظیر فضای دیسک بیشتر ، ترافیک نامحدود، CPU و RAM قوی تر و هاردهای NVMe یا SSD نیاز دارند."
              id="web3"
            />
          </div>
          <div className="mySlideser fadeer l-d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <HostingServiceCard
              title="هاست نامحدود"
              caption="هاست های تمام نامحدود ما ، مناسب کسب و کار هایی با ترافیک بالا و منابع زیاد هستند . در این سرویس هیچ محدودیتی برای استفاده وجود ندارد . "
              id="web2"
            />
          </div>
          <div className="mySlideser fadeer l-d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <HostingServiceCard
              title="هاست ابری"
              caption="Cloud Hosting  یا میزبانی ابری نوعی از میزبانی است که اساس فعالیت آن بر اساس رایانش ابری می باشد . در این نوع از میزبانی وب سایت ها به جای استفده از منابع سخ افزاری و نرم افزاری یک سرور ، از چندین سرور به طور همزمان استفاده میشود. "
              id="web1"
            />
          </div>
          <div className="dotted-containerer">
            <img
              src={al}
              className="al-doti"
              onClick={() => {
                plusSlideser(-1);
              }}
            />
            <span
              className="doter"
              onClick={() => {
                currentSlideer(1);
              }}
            ></span>
            <span
              className="doter"
              onClick={() => {
                currentSlideer(2);
              }}
            ></span>
            <span
              className="doter"
              onClick={() => {
                currentSlideer(3);
              }}
            ></span>
            <img
              src={arr}
              className="ar-doti"
              onClick={() => {
                plusSlideser(1);
              }}
            />
          </div>
        </div> 
        <div className="host-v-1"></div> 
      </div> 
      <div className="xs-d-none l-d-block container mb-150">
        <div className="d-flex flex-row justify-content-between">
          <div>
            <div className="d-flex flex-column justify-content-center align-items-end h-100">
              <div className="position-relative">
                <p className="info-header c-white bold">پر‌سرعت و حرفه ای</p>
                <p className="orange-box position-absolute z-n-1"></p>
              </div>
              <div>
                <p className="info-en c-white-70 f-16">Hosting and Server</p>
              </div>
              <div>
                <p className="info-txt rtl c-white-70">
                  با سرویس های هاستینگ ما هیچ نگرانی بابت سرعت و امنیت نداشته
                  باشید. خدمات هاستینگ تیم عماد مزایا بسیار زیادی برای شما رقم
                  میزند که میتواند به شما و کسب و کار شما کمک بسیاری کنند.
                </p>
              </div>
              <div>
                <p className="info-list c-white-70">
                  بهره‌گیری از پیشرفته‌ترین فناوری‌های روز دنیا {" "}
                  <span>
                    <img src={tick} />
                  </span>
                </p>
              </div>
              <div>
                <p className="info-list c-white-70">
                  پشتیبانی حرفه ای و مشاوره در زمینه انتخاب پلن{" "}
                  <span>
                    <img src={tick} />
                  </span>
                </p>
              </div>
              <div>
                <p className="info-list c-white-70">
                  امنیت و محرمانگی بالای اطلاعات مشتریان و فایل ها{" "}
                  <span>
                    <img src={tick} />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="ml-128">
            <img src={server} />
          </div>
        </div>
      </div>
      <div id="host-plans" className="container">
        <HostingShop hn="true" br="true" />
        {/* <div className="w-100 d-flex justify-content-center align-items-center mb-20">
          <div className="d-flex justify-content-start align-items-center">
            <div>
              <img className="pointer range-arrow" src={al} />
            </div>
            <div className="slidecontainer mx-20">
              <input
                type="range"
                min="1"
                max="100"
                value="0"
                className="slider"
                id="myRange"
              />
            </div>
            <div>
              <img className="pointer range-arrow" src={ar} />
            </div>
          </div>
        </div> */}
      </div>
      <div className="space-host-lg-1"></div>
      <div className="view-form-bg">
        <div className="view-form-container d-flex flex-column-reverse flex-lg-row justify-content-between align-items-end align-items-lg-center">
          <div className="xs-d-none l-d-flex justify-content-center align-items-center">
            <input
              className="mr-15"
              placeholder="نام دامنه را بدون www وارد کنید"
              value={domainName}
              onChange={(e) => setDomainName(e.target.value)}
            />
            <div
              onClick={dropOpener}
              className="position-relative pointer mr-15 drop-view d-flex justify-content-between align-items-center"
            >
              <p>.{com}</p>
              <p>
                <img src={arrowDown} />
              </p>
              <div id="ddot3" className="droper-close">
                <div
                  onClick={() => {
                    setcom("com");
                  }}
                  className="item"
                >
                  <p
                    onClick={() => {
                      setcom("com");
                    }}
                    className="c-white"
                  >
                    .com
                  </p>
                </div>
                <div
                  onClick={() => {
                    setcom("net");
                  }}
                  className="item"
                >
                  <p
                    onClick={() => {
                      setcom("net");
                    }}
                    className="c-white"
                  >
                    .net
                  </p>
                </div>
                <div
                  onClick={() => {
                    setcom("org");
                  }}
                  className="item"
                >
                  <p
                    onClick={() => {
                      setcom("org");
                    }}
                    className="c-white"
                  >
                    .org
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={handleSearch}
              className="pointer bg-main br-5 search-view-btn"
            >
              <img src={search} />
            </div>
          </div>
          <div className="w-100 xs-d-flex l-d-none flex-column mb-40 ">
            <div className="d-flex mb-10">
              <input
                className="mr-15"
                placeholder="نام دامنه را بدون www وارد کنید"
                value={domainName}
                onChange={(e) => setDomainName(e.target.value)}
              />
              <div
                onClick={dropOpener}
                className="position-relative pointer drop-view d-flex justify-content-between align-items-center"
              >
                <p>.{com}</p>
                <p>
                  <img src={arrowDown} />
                </p>
                <div id="ddot2" className="droper-close">
                  <div
                    onClick={() => {
                      setcom("ir");
                    }}
                    className="item"
                  >
                    <p
                      onClick={() => {
                        setcom("ir");
                      }}
                      className="c-white"
                    >
                      .ir
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setcom("tv");
                    }}
                    className="item"
                  >
                    <p
                      onClick={() => {
                        setcom("tv");
                      }}
                      className="c-white"
                    >
                      .tv
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setcom("com");
                    }}
                    className="item"
                  >
                    <p
                      onClick={() => {
                        setcom("com");
                      }}
                      className="c-white"
                    >
                      .com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <MainBtnOpen text="جست و جوی دامین" />
            </div>
          </div>
          <div className="d-flex flex-column align-items-end mb-20">
            <p className="f-23 c-white mb-10 rtl">جست و جوی دامنه</p>
            <p className="f-13 c-white-70 rtl">
              از طریق باکس زیر میتوانید دامین مدنظر خود را با پسوند دلخواه خود
              جست و جو کنید
            </p>
          </div>
        </div>
      </div>
      <div className="space-host-lg-2"></div>
      <div className="container">
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-lg-between">
          <div className="w-100 mr-34">
            <Net />
            <Info />
            <Store />
            <Blog />
          </div>
          <div className="w-100">
            <Com />
            <Org />
            <Online />
            <Site />
          </div>
        </div>
      </div>
      <div className="xs-d-none l-d-block">
        <div className="space-host-lg-3"></div>
        <Improve />
        <div className="space-host-lg-4"></div>
        <div className="container">
          <MainForm />
        </div>
        <div className="space-host-lg-5"></div>
        <Footer />
      </div>
      <div className="xs-d-block l-d-none">
        <FooterMob />
      </div>
    </div>
  );
}

export default HostingView;