import "./Design.css";
import GraphicPen from "../../Components/GraphicPen/GraphicPen";
import GraphicView from "../../Components/GraphicView/GraphicView";
import ViewCall from "../../Components/ViewCall/ViewCall";
import ScrollOne from "../../sections/ScrollOne/ScrollOne";
import Header from "../../sections/Header/Header";
import Improve from "../../sections/Improve/Improve";
import MainForm from "../../sections/MainForm/MainForm";
import Footer from "../../sections/Footer/Footer";
import BannerDesign from "../../sections/BannerDesign/BannerDesign";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bannerDesign from "../../Images/bannerDesign.png";
import HeaderMob from "../../sections/HeaderMob/HeaderMob";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen";
import { Helmet } from 'react-helmet';

function Design() {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <title>تیم عماد | خدمات طراحی گرافیک</title>
        <meta name="description" content="صفحه خدمات طراحی گرافیک تیم عماد. ارائه دهنده خدمات طراحی لوگو، تیزر تبلیغاتی، تصاویر شبکه‌های اجتماعی و طراحی UI/UX." />
        <meta name="keywords" content="تیم عماد, طراحی گرافیک, طراحی لوگو, تیزر تبلیغاتی, UI/UX, گرافیک" />
      </Helmet>
      <div className="xs-d-none lg-d-block design-container">
        <Header />
        <div className="banner-d flex-fill">
          <BannerDesign />
        </div>
        <div className="xs-mb-0 l-mb-150"></div>
        <div id="design" className="xs-d-none l-d-block container wavers mb-150">
          <div className="d-flex justify-content-between">
            <GraphicPen
              title="طراحی لوگو"
              caption="هر برند و کسب و کاری به یک لوگو احتیاج دارد. ما میتوانیم برای شما لوگویی زیبا و متناسب با شخصیت برند و چهره کسب و کارتان طراحی کنیم."
            />
            <GraphicPen
              title="طراحی تیزر تبلیغاتی"
              caption="با یک تیزر تبلیغاتی حرفه ای میتوانید کسب و کار خود را به بهترین شکل ممکن به مشتری ها معرفی کنید و فروش خود را افزایش دهید."
            />
            <GraphicPen
              title="طراحی تصاویر برای شبکه های اجتماعی"
              caption="طراحی انواع تصاویر برای پست ها و استوری های اینستاگرام و شبکه های اجتماعی دیگر یکی از خدمات حرفه ای شرکت عماد است."
            />
            <GraphicPen
              title="طراحی UI و UX نرم افزار"
              caption="طراحی UI و UX برای انواع اپلیکیشن و وبسایت به وسیله نرم افزار های Adobe XD، Figma و Photoshop یکی از مهم ترین خدمات ما است."
            />
          </div>
        </div>
        <div className="xs-d-none l-d-block mb-150">
          <GraphicView />
        </div>
        <div className="xs-d-none l-d-block container mb-150">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <ViewCall />
            </div>
            <div className="w-100 h-100 d-flex flex-column align-items-end">
              <div className="mb-60 position-relative">
                <p className="rtl c-white bold f-30">
                  چرا خدمات طراحی گرافیک شرکت عماد؟
                </p>
                <p className="orange-box position-absolute z-n-1"></p>
                <p className="rtl c-white-70 f-16">WHY YOU NEED TO CHOOSE US</p>
              </div>
              <div className="ml-80">
                <p className="text-align-justify rtl c-white-70 f-16">
                  برای طراحی یک طرح گرافیکی حرفه ای و جذاب تنها نیاز به دانش فنی
                  از نرم افزار های طراحی نیست. خلاقیت و ایده پردازی سهم بزرگتری
                  نسبت به دانش فنی دارند. ما در تیم عماد با سال ها سابقه و طراحی
                  طرح ها با انواع سبک و شکل و شمایل به همراه خلاقیت بالای اعضای
                  تیم خود، انتخابی مناسب برای کسب و کار شما هستیم.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="xs-d-none l-d-block mb-150">
          <ScrollOne id="graphicscroll" />
        </div>
        <div className="xs-d-none l-d-block">
          <Improve />
          <div className="space-design"></div>
          <div className="container">
            <MainForm />
          </div>
          <div className="space-design-2"></div>
          <Footer />
        </div>
      </div>

      <div className="xs-d-block lg-d-none design-mobile-container">
        <div className="design-header-mobile">
          <HeaderMob />
        </div>
        <div className="arz-margin">
          <div className="design-banner-mobile">
            <img src={bannerDesign} />
          </div>
          <div className="design-header1-mobile">
            <p>لذت اوج زیبایی و ظرافت</p>
          </div>
          <div className="design-header2-mobile">
            <p>گرافیک و طراحی مدرن</p>
          </div>
          <div className="design-header3-mobile">
            <p>خدمات حرفه ای طراحی گرافیک</p>
          </div>
          <div className="design-text-mobile">
            <p>
              خدمات کسب و کار خودرا برای حرفه‌ای تر شدن به تیم ما بسپارید . ما با
              ارائه خدمات سطح بالا در ضمینه طراحی با بروزترین استاندارد ها به شما کمک
              خواهیم کرد
            </p>
          </div>
          <div className="design-main-btn-mobile position-absolute">
            <MainBtnOpen link="/contact" text="مشاوره رایگان طراحی" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Design;
