import './ContactCard.css';
import calling from '../../Images/calling.png';
import message from '../../Images/message.png';

function ContactCard(props){
    return (
        <div className='contact-card-container d-flex justify-content-center align-items-center'>
            <div className='d-flex flex-column align-items-center w-100 contact-card-inner'>
                <div className='contact-card-icon d-flex justify-content-center align-items-center'>
                    <img src={(props.icon === 'calling') ? calling : message} />
                </div>
                <div className='contact-card-header c-white w-100'>
                    <p className='w-100 text-center'>{props.header}</p>
                </div>
                <div className='contact-card-text c-white-80 w-100'>
                    <p className='w-100 text-center'>{props.text}</p>
                </div>
            </div>
        </div>
    );
}

export default ContactCard;