import './PageSix.css';
import BlogLine from '../../Components/BlogLine/BlogLine.js';
import Blog from '../../sections/Blog/Blog.js';
import Pagination from '../../Components/Pagination/Pagination';
import Header from '../../sections/Header/Header';
import Improve from '../../sections/Improve/Improve';
import MainForm from '../../sections/MainForm/MainForm';
import Footer from '../../sections/Footer/Footer';
import HeaderBlog from '../../sections/HeaderBlog/HeaderBlog';
import FooterMob from '../../sections/FooterMob/FooterMob'; 
import {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';

function PageSix(){
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[]);

    return (
        <div className='page-six-container'>
            <Helmet>
                <title>بلاگ - تیم عماد</title>
                <meta name="description" content="صفحه بلاگ تیم عماد" />
                <meta name="keywords" content="تیم عماد, بلاگ, مقالات" />
            </Helmet>
            <div className='xs-d-none l-d-block'>
                <Header />
            </div>
            <div className='xs-d-block l-d-none'>
                <HeaderBlog />
            </div>
            <div className='space-6-1'></div>
            <div className='xs-d-none l-d-block'>
                <BlogLine />
                <div className='space-6-2'></div>
            </div>
            <div className='container blogg-content-container'>
                <Blog />
            </div>
            <div className='space-6-7'></div>
            {/* <div className="container d-flex flex-column align-items-center">
                <Pagination />
                <div className="space-6-6"></div>
            </div> */}
            <div className='xs-d-block l-d-none'>
                <FooterMob />
            </div>
            <div className='xs-d-none l-d-block'>
                <div className='space-6-3'></div>
                <Improve />
                <div className='space-6-4'></div>
                <div className='container'>
                    <MainForm />
                </div>
                <div className='space-6-5'></div>
                <Footer />
            </div>
        </div>
    );
}

export default PageSix;
