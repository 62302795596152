import './BlogLine.css';
import blog from '../../Images/blog.png';
import line from '../../Images/line.png';

function AboutUsLine(){
    return (
        <div className='about-us-line d-flex justify-content-center align-items-center' style={{backgroundImage: 'url('+line+')'}}>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column align-items-end'>
                    <div>
                        <p className='c-white bold fa'>بلاگ</p>
                    </div>
                    <div>
                        <p className='c-white en'>Blog</p>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <img src={blog} />
                </div>
            </div>
        </div>
    );
}

export default AboutUsLine;