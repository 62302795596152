import './DropInsta.css';
import arrowdown from '../../Images/arrowdown.png';
import instagramMain from '../../Images/instagram-main.png';
import left from '../../Images/left.png';
import { useState } from 'react';

function DropInsta(props){
    // const [open, setOpen] = useState(false);

    // const instaOpen = () => {
    //     if(!open) {
    //         setOpen(true);
    //         document.getElementById(props.id).classList.add('drop-insta-container-open');
    //         document.getElementById(props.id).classList.remove('drop-insta-container');
    //     }else{
    //         setOpen(false);
    //         document.getElementById(props.id).classList.remove('drop-insta-container-open');
    //         document.getElementById(props.id).classList.add('drop-insta-container');
    //     }
    // }

    const openPU = () => {
        props.onChange();
        
    }


    return (
        <div id={props.id} className='drop-insta-container mb-33'>
            <div className='pointer drop-insta-btn d-flex justify-content-between align-items-center'>
                <div className='drop-insta-btn-icon'>
                    <img src={arrowdown} />
                </div>
                <div className='d-flex'>
                    <div>
                        <p className='c-white bold f-16'>
                            اینستاگرام
                        </p>
                    </div>
                    <div className='drop-insta-icon ml-13'>
                        <img src={instagramMain} />
                    </div>
                </div>
            </div>
            <div className='drop-insta-content'>
                <div onClick={openPU} className="insta-content-bb px-12 d-flex justify-content-between align-items-center">
                    <div className='insta-content-icon'>  
                        <img src={left} />
                    </div>
                    <div>
                        <p className="c-white-70">
                            لایک
                        </p>
                    </div>
                </div>
                <div onClick={openPU} className="insta-content-bb px-12 d-flex justify-content-between align-items-center">
                    <div className='insta-content-icon'>
                        <img src={left} />
                    </div>
                    <div>
                        <p className="c-white-70">
                            فالوئر
                        </p>
                    </div>
                </div>
                <div onClick={openPU} className="insta-content-bb px-12 d-flex justify-content-between align-items-center">
                    <div className='insta-content-icon'>
                        <img src={left} />
                    </div>
                    <div>
                        <p className="c-white-70">
                            بازدید استوری
                        </p>
                    </div>
                </div>
                <div onClick={openPU} className="insta-content-bb px-12 d-flex justify-content-between align-items-center">
                    <div className='insta-content-icon'>
                        <img src={left} />
                    </div>
                    <div>
                        <p className="c-white-70">
                            بازدید پست و ویدیو
                        </p>
                    </div>
                </div>
                <div onClick={openPU} className=" px-12 d-flex justify-content-between align-items-center">
                    <div className='insta-content-icon'>
                        <img src={left} />
                    </div>
                    <div>
                        <p className="c-white-70">
                           فالوئر فیک 
                        </p>
                    </div>                
                </div>
            </div>
        </div>
    );
}

export default DropInsta;