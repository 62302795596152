import './ShopItemCard.css';
import arrowLeftB from '../../Images/arrowleftb.png';

function ShopItemCard({ host }) {
    // Extracting the link from host object
    const hostLink = host.link;

    const handleClick = () => {
        window.open(hostLink, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='hosting-drop-buy' id="dropbuy">
            <div className='hosting-drop-buy-header c-white bold d-flex flex-row justify-content-center align-items-center'>
                <p>{host.title}</p>
            </div>
            <div className='hosting-drop-buy-main-in d-flex flex-column align-items-end w-100'>
                <div className="w-100 mb-13 drop-buy-head-shop">
                    <div className="w-100 d-flex mb-10">
                        <p className="c-white f-23 bold w-100 text-align-center rtl">
                            {host.price}
                        </p>
                    </div>
                    <div className='position-relative mb-12 w-100 h-100'>
                        <div className='shop-hr'></div>
                        <p className='header-in-line f-16'>{host.divider}</p>
                    </div>
                </div>
                <div className="w-100 drop-buy-item-container-shop mt-12">
                    {host.features.map((feature, index) => (
                        <div key={index} className="d-flex justify-content-between w-100 mb-26">
                            <span className="f-12 c-white-70 d-block"></span>
                            <span className="d-flex align-items-center f-12 c-white-70 rtl">
                                <span className="circle-orange d-block ml-8"></span>{feature}
                            </span>
                        </div>
                    ))}
                </div>
                <div>
                    <button onClick={handleClick} className="btn-white-info">
                        <span><img className="btn-info-icon" src={arrowLeftB} alt="arrow" /></span>
                        انتخاب پلن
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ShopItemCard;
