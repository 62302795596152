// PostWeb.js
import React, { useEffect, useState } from 'react';
import PostCard from '../../Components/PostCard/PostCard.js';
import './PostWeb.css';
import blog from '../../Images/blog.png';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function PostWeb() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('https://emad.team/mag/wp-json/wp/v2/posts');
                const allPosts = response.data;

                // انتخاب سه پست تصادفی
                const randomPosts = [];
                while (randomPosts.length < 3 && allPosts.length > 0) {
                    const randomIndex = Math.floor(Math.random() * allPosts.length);
                    randomPosts.push(allPosts.splice(randomIndex, 1)[0]);
                }

                // دریافت تصاویر پست‌ها
                const postsWithImages = await Promise.all(randomPosts.map(async post => {
                    if (post.featured_media) {
                        const mediaResponse = await axios.get(`https://emad.team/mag/wp-json/wp/v2/media/${post.featured_media}`);
                        post.featured_media_url = mediaResponse.data.source_url;
                    }
                    return post;
                }));

                setPosts(postsWithImages);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const baseColor = "#222222";
    const highlightColor = "#444444";

    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className='container'>
            <div className="d-flex flex-column align-items-center">
                <div className="blog-web-icon-container">
                    <img className="blog-web-icon" src={blog} />
                </div>
                <div className="blog-web-header-fa-container">
                    <p className="blog-web-header-fa c-white">مطالب وبلاگ</p>
                </div>
                <div className="blog-web-header-en-container">
                    <p className="blog-web-header-en c-white">BLOG POSTS</p>
                </div>
            </div>
            <div className="posts d-flex justify-content-between">
                {loading ? (
                    Array.from({ length: 3 }).map((_, index) => (
                        <div key={index} className='post-web-post-card'>
                            <PostCard
                                width="e"
                                title={<Skeleton width={150} baseColor={baseColor} highlightColor={highlightColor} />}
                                excerpt={<Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} />}
                                date={<Skeleton width={80} baseColor={baseColor} highlightColor={highlightColor} />}
                                categories={<Skeleton width={80} baseColor={baseColor} highlightColor={highlightColor} />}
                                image={<Skeleton height={200} width="100%" baseColor={baseColor} highlightColor={highlightColor} />}
                                id={<Skeleton width={50} baseColor={baseColor} highlightColor={highlightColor} />}
                                slug={<Skeleton width={50} baseColor={baseColor} highlightColor={highlightColor} />}
                            />
                            <div className='l-mr-50 xs-mb-0'></div>
                        </div>
                    ))
                ) : (
                    posts.map((post, index) => (
                        <div key={post.id} className='post-web-post-card'>
                            <PostCard
                                width="e"
                                title={post.title.rendered}
                                excerpt={post.excerpt.rendered}
                                date={post.date}
                                author={post.author}
                                categories={post.categories}
                                image={post.featured_media_url}
                                id={post.id}
                                slug={post.slug}
                            />
                            {index < posts.length - 1 && <div className='l-mr-50 xs-mb-0'></div>}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default PostWeb;
