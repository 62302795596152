import './FAQS.css';
import MainDropDown from '../../Components/MainDropDown/MainDropDown';
import calling from '../../Images/calling.png';
import FAQSCard from '../../Components/FAQSCard/FAQSCard';
import FAQSCard2 from '../../Components/FAQSCard/FaqBoxList/FAQSCard2'
import FAQSCard3 from '../../Components/FAQSCard/FaqBoxList/FAQSCard3'
import FAQSCard4 from '../../Components/FAQSCard/FaqBoxList/FAQSCard4'
import FooterMob from '../FooterMob/FooterMob';

function FAQS() {
    return(
    <>
        <div className='faqs-maindrop-container'>
            <MainDropDown/>
        </div>
        <div className='xs-d-flex l-d-none justify-content-between'>
            <div className="position-relative flex-fill xs-mr-0 l-mr-40 d-flex flex-column flex-lg-row justify-content-between">
                <FAQSCard />
                <div className='xs-mb-20 l-mb-0'></div> 
                <FAQSCard2 /> 
            </div>
            <div className='btb'></div>
            <div  className="postition-relative flex-fill d-flex flex-column flex-lg-row justify-content-between">
                <FAQSCard3 />
                <div className='xs-mb-20 l-mb-0'></div> 
                <FAQSCard4 /> 
            </div>
        </div>
        <div className='xs-d-none l-d-flex justify-content-between'>
                <FAQSCard4 />
                <FAQSCard3 /> 
                <FAQSCard2 />
                <FAQSCard /> 
        </div>
    </>
    );
}

export default FAQS;