import "./BannerHosting.css";
import bannerHosting from "../../Images/bannerHosting.png";
import MainBtn from "../../Components/MainBtn/MainBtn.js";
import SecondBtn from "../../Components/SecondBtn/SecondBtn.js";
import mouse from "../../Images/mouse.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function BannerHosting() {
  const [bhh, setBhh] = useState(0);

  useEffect(() => {
    setBhh(window.innerHeight - 131);
  }, []);

  const handleScroll = () => {
    document.getElementById("hosting").scrollIntoView();
  };

  return (
    <div
      style={{ height: bhh + "px" }}
      className="container-fluid banner-container d-flex align-items-center"
    >
      <div className="container d-flex justify-content-between align-items-center w-100 banner-container-inside">
        <div className="banner-hosting-image-container">
          <img src={bannerHosting} />
        </div>
        <div className="ml-160 banner-hosting-title-container d-flex flex-column align-items-end">
          <div className="title-1">
            <p className="c-white bold rtl">هاست پرسرعت و فضای ابری</p>
          </div>
          <div className="title-1">
            <p className="c-white bold rtl">با پهنای باند ویژه و بالا</p>
          </div>
          <div className="title-2">
            <p className="c-white bold rtl">
              <span className="c-main">خدمات حرفه ای هاستینگ و ثبت دامنه</span>
            </p>
          </div>
          <div className="title-text">
            <p className="c-white-70 rtl text-align-justify">
              اوج سرعت و امنیت را با هاست های ما تجربه کنید. اگر به یک سیستم
              هاست حرفه ای احتیاج دارید میتوانید به ما اعتماد کنید و از بهترین
              هاست ها با بهترین نرم افزار و سخت افزار استفاده کنید. سرور های ما
              میزبان مناسبی برای وبسایت و یا اپلیکیشن شما هستند.
            </p>
          </div>
          <div className="d-flex justify-content-end w-100">
            <a className="link1" href="#host-plans">
              <MainBtn text="سفارش هاست" />
            </a>
            <div className="l-btn"></div>
            <Link className="link1" to="/hosting-plan/shop">
              <SecondBtn text="مشاهده پلن ها" />
            </Link>
          </div>
        </div>
      </div>
      <div className="mouse-container d-flex flex-column align-items-center">
        <img className="mb-10 pointer" src={mouse} onClick={handleScroll} />
        <p className="c-white-20 default">اسکرول کنید</p>
      </div>
    </div>
  );
}

export default BannerHosting;
