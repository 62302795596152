import './InstaPU.css';
import closeIcon from '../../Images/close-icon.png';
import downarrow from '../../Images/downarrow.png';
import leftarrow from '../../Images/leftarrow.png';
import insta from '../../Images/insta.png';
import { useEffect, useState } from 'react';

function InstaPU(props){
    const closePU = () => {
        props.onChange();
    }

    return (
        <div  className='insta-pu-container d-flex justify-content-center align-items-center'>
            <div className="insta-popup-container d-flex">
                <div className="insta-popup-left d-flex flex-column justify-content-end w-65">
                    <div className="popup-content-header mb-30 d-flex justify-content-between align-items-center">
                        <img onClick={closePU} className="pointer close-icon-select" src={closeIcon} alt=""/>
                        <p className="c-white">افزایش لایک اینستاگرام</p>
                    </div>
                    <div>
                        <div className="pointer popup-drop mb-20 d-flex justify-content-between align-items-center">
                            <img className="popup-down-arrow" src={downarrow} alt=""/>
                            <p className="c-white-30">تعداد لایک را انتخاب کنید</p>
                        </div>
                        <div className="pointer popup-drop mb-20 d-flex justify-content-between align-items-center">
                            <img className="popup-down-arrow" src={downarrow} alt=""/>
                            <p className="c-white-30">نوع لایک را انتخاب کنید</p>
                        </div>
                        <div className="pointer popup-drop mb-13 d-flex justify-content-end align-items-center">
                            <p className="c-white-30">لینک پست</p>
                        </div>
                        <div>
                            <p className="rtl mb-105 c-main">لطفا قبل از سفارش حتما اکانت خود را از حالت <span> private </span>خارج کنید</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <button className="popup-btn c-white bold">
                            ثبت سفارش
                        </button>
                    </div>
                </div>
                <div className="insta-popup-right w-35">
                    <div className=" box-head-container mb-13 d-flex flex-column justify-content-center align-items-center">
                        <img className="popup-right-icon mb-13" src={insta} alt=""/>
                        <p className="c-white bold mb-55">اینستاگرام</p>
                    </div>
                    <div className=" d-flex flex-column justify-content-center align-items-center">
                        <div className="pointer popup-sidebar-items mb-13 d-flex justify-content-between w-100 align-items-center">
                            <img className="popup-right-arrow" src={leftarrow} alt=""/>
                            <p className="c-white">لایک</p>
                        </div>
                        <div className="pointer popup-sidebar-items mb-13 d-flex justify-content-between w-100 align-items-center">
                            <img className="popup-right-arrow" src={leftarrow} alt=""/>
                            <p className="c-white">فالوور</p>
                        </div>
                        <div className="pointer popup-sidebar-items mb-13 d-flex justify-content-between w-100 align-items-center">
                            <img className="popup-right-arrow" src={leftarrow} alt=""/>
                            <p className="c-white">بازدید استوری</p>
                        </div>
                        <div className="pointer popup-sidebar-items mb-13 d-flex justify-content-between w-100 align-items-center">
                            <img className="popup-right-arrow" src={leftarrow} alt=""/>
                            <p className="c-white">بازدید پست و ویدیو</p>
                        </div>
                        <div className="pointer popup-sidebar-items1 mb-13 d-flex justify-content-between w-100 align-items-center">
                            <img className="popup-right-arrow" src={leftarrow} alt=""/>
                            <p className="c-white">فالوور فیک</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default InstaPU;