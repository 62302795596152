import './Logo.css';
import logo from '../../Images/logo.png';

function Logo() {
    return (
        <div className='logo-container pointer'>
            <img src={logo} alt="Emad App Logo" />
        </div>
    );
}

export default Logo;