import "./ServiceCard.css";
import MainBtnWhite from "../MainBtnWhite/MainBtnWhite";
import { Link } from "react-router-dom";

function ServiceCard(props) {
  const { title, caption, url, calling } = props;
  return (
    <>
      <div className="xs-d-flex l-d-none service-card-s flex-row-reverse">
        <div className="service-card-icon-container-s">
          <img className="service-card-icon-s" src={calling} alt="" />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-end xs-content">
          <div className="service-card-headers bold c-white f-16">
            <p>{title}</p>
          </div>
          <div className="service-card-txt rtl c-white-70">
            <p>{caption}</p>
          </div>
        </div>
      </div>
      <div className="xs-d-none l-d-flex service-card flex-column align-items-center">
        <div className="service-card-icon-container d-flex justify-content-center align-items-center">
          <img className="service-card-icon" src={calling} alt="" />
        </div>
        <div className="service-card-header bold c-white">
          <p>{title}</p>
        </div>
        <div className="service-card-txt rtl c-white-70">
          <p>{caption}</p>
        </div>
        {url && (
          <div className="service-card-btn">
            <Link to={url}>
              <MainBtnWhite text="بیشتر بدانید" />
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

export default ServiceCard;
