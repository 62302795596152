import "./MainDropDown.css";
import down from "../../Images/down.png";
import { useState, useEffect } from "react";

function MainDropDown(props) {
  const [display1, setDisplay1] = useState("none");
  const [display2, setDisplay2] = useState("none");
  const [display3, setDisplay3] = useState("none");
  const [display4, setDisplay4] = useState("none");

  const mydropdown1 = (id) => {
    let elementDisplay = document.getElementById(id).style.display;
    if (elementDisplay == "none") {
      setDisplay1("block");
    }
    if (elementDisplay == "block") {
      setDisplay1("none");
    }
  };
  const mydropdown2 = (id) => {
    let elementDisplay = document.getElementById(id).style.display;
    if (elementDisplay == "none") {
      setDisplay2("block");
    }
    if (elementDisplay == "block") {
      setDisplay2("none");
    }
  };
  const mydropdown3 = (id) => {
    let elementDisplay = document.getElementById(id).style.display;
    if (elementDisplay == "none") {
      setDisplay3("block");
    }
    if (elementDisplay == "block") {
      setDisplay3("none");
    }
  };

  const mydropdown4 = (id) => {
    let elementDisplay = document.getElementById(id).style.display;
    if (elementDisplay == "none") {
      setDisplay4("block");
    }
    if (elementDisplay == "block") {
      setDisplay4("none");
    }
  };

  useEffect(() => {
    document.getElementById("id-1").style.display = display1;
  }, [display1]);
  useEffect(() => {
    document.getElementById("id-2").style.display = display2;
  }, [display2]);
  useEffect(() => {
    document.getElementById("id-3").style.display = display3;
  }, [display3]);
  useEffect(() => {
    document.getElementById("id-4").style.display = display4;
  }, [display4]);

  return (
    <div>
      <button
        onClick={() => {
          mydropdown1("id-1");
        }}
        className="main-drop-down-btn c-white rtl d-flex justify-content-between align-items-center"
      >
        <span>نحوه ارتباط شما با شرکت عماد به چه صورت هست؟</span>
        <img src={down} />
      </button>

      <div id="id-1" className="main-drop-down-content c-white">
        <p className="rtl c-white-70">
          انواع راه های ارتباطی برای شما باز هست. ارتباط تلفنی ، ارتباط آنلاین
          از طریق واتس اپ ، تلگرام ، پیامک . ارتباط آنلاین از طریق چت در سایت و
          اپلیکیشن . ارتباط از طریق سیستم تیکتینگ
        </p>
      </div>

      <div className="mb-19"></div>

      <button
        onClick={() => {
          mydropdown2("id-2");
        }}
        className="main-drop-down-btn c-white rtl d-flex justify-content-between align-items-center"
      >
        <span>ساعت کاری شما به چه صورت هست؟</span>
        <img src={down} />
      </button>

      <div id="id-2" className="main-drop-down-content c-white">
        <p className="rtl c-white-70">
          ساعت کاری ما برای مشاوره یا و پاسخگویی به شما عزیزان از ۷ صبح الی ۱
          بامداد می باشد . اما پشتیبانی فنی ما ۲۴ ساعته می باشد و در هر زمان به
          مشکل خوردین ما هستیم تا مشکل را حل کنیم.
        </p>
      </div>

      <div className="mb-19"></div>

      <button
        onClick={() => {
          mydropdown3("id-3");
        }}
        className="main-drop-down-btn c-white rtl d-flex justify-content-between align-items-center"
      >
        <span>قرار داد پروژه ها به چه صورت هست؟</span>
        <img src={down} />
      </button>

      <div id="id-3" className="main-drop-down-content c-white">
        <p className="rtl c-white-70">
          خدمات طراحی سایت ، طراحی اپلیکیشن ، طراحی گرافیک ، طراحی تیزر شامل عقد
          قرارداد رسمی شرکت عماد خواهد بود که بعد از صحبت های اولیه نسخه
          الکترونیک برای شما ارسال خواهد شد.
        </p>
      </div>

      <div className="mb-19"></div>

      <button
        onClick={() => {
          mydropdown4("id-4");
        }}
        className="main-drop-down-btn c-white rtl d-flex justify-content-between align-items-center"
      >
        <span>برای انجام خدمات آیا فاکتور صادر خواهد شد؟</span>
        <img src={down} />
      </button>

      <div id="id-4" className="main-drop-down-content c-white">
        <p className="rtl c-white-70">
          بله ، در صورت نیاز شما عزیزان فاکتور رسمی ارائه خواهیم داد .
        </p>
      </div>
    </div>
  );
}

export default MainDropDown;
