import './MainForm.css';
import Form1 from '../../Components/Form1/Form1.js';
import messageLogo from '../../Images/message.png';

function MainForm() {
    return (
        <div className="d-flex flex-column align-items-center mainform-container">
            <div>
                <img className="icon" src={messageLogo} />
            </div>
            <div>
                <p className="mainform-fa-text c-white">فرم مشاوره رایگان</p>
            </div>
            <div>
                <p className="mainform-en-text c-white">GET A FREE QOUTE</p>
            </div>
            <div className="mainform-index-container">
                <Form1 />
            </div>
        </div>
    );
}

export default MainForm;