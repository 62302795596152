import "./Information.css";
import infoOne from "../../Images/info-one.png";
import infoTwo from "../../Images/info-two.png";
import infoThree from "../../Images/info-three.png";
import tick from "../../Images/tick.png";
import { useState, useEffect } from "react";

function Information() {
  const [slideIndexi, setSlideIndexi] = useState(1);

  const plusSlidesi = (n) => {
    setSlideIndexi(slideIndexi + n);
    showSlidesi(slideIndexi);
  };

  const showSlidesi = (n) => {
    let s = slideIndexi;
    let i;
    let slidesi = document.getElementsByClassName("mySlidesi");
    if (n > slidesi.length) {
      setSlideIndexi(1);
      s = 1;
    }
    if (n < 1) {
      setSlideIndexi(slidesi.length);
      s = slidesi.length;
    }
    for (i = 0; i < slidesi.length; i++) {
      slidesi[i].style.display = "none";
    }
    slidesi[s - 1].style.display = "flex";
  };

  useEffect(() => {
    showSlidesi(slideIndexi);
  }, []);

  return (
    <div className="position-relative container slideshow-containeri wd-flex flex-column w-100 c-white info-container">
      <div className="mySlidesi fadei l-d-flex flex-column flex-lg-row justify-content-between align-items-center">
        <div className="xs-mb-60 l-mb-0 d-flex justify-content-center align-items-center">
          <img className="info-one-img" src={infoOne} />
        </div>
        <div className="xs-mr-30 m-mr-0 d-flex flex-column justify-content-center align-items-end info-content h-100">
          <div className="position-relative ">
            <p className="info-header bold">طراحی وبسایت</p>
            <p className="orange-box position-absolute z-n-1"></p>
          </div>
          <div>
            <p className="info-en">WEB DESIGN</p>
          </div>
          <div>
            <p className="info-txt rtl c-white-70">
              ما با سالها سابقه در زمینه <b>طراحی وبسایت</b> میتوانیم پروژه شما
              را به بهترین شکل ممکن انجام دهیم و وبسایتی مطابق با اصول UI و
              سلیقه شما را به شما ارائه دهیم و کسب و کار شما را به یک کسب و کار
              اینترنتی تبدیل کنیم.
            </p>
          </div>
          <div>
            <p className="info-list">
              طراحی اختصاصی ۰ تا ۱۰۰
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
          <div>
            <p className="info-list">
              کد نویسی با رعایت استاندارد ها
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
          <div>
            <p className="info-list">
              پشتیبانی از پروژه ها بدون محدودیت زمانی
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
        </div>
        <div
          onMouseDown={() => {
            plusSlidesi(-1);
          }}
          className="lth-open"
        ></div>
        <div
          onMouseDown={() => {
            plusSlidesi(1);
          }}
          className="rth-open"
        ></div>
      </div>
      <div className="mySlidesi fadei l-d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center">
        <div className="xs-mr-30 m-mr-0 d-flex flex-column justify-content-center align-items-end h-100">
          <div className="position-relative">
            <p className="info-header bold">هاستینگ و سرور</p>
            <p className="orange-box position-absolute z-n-1"></p>
          </div>
          <div>
            <p className="info-en">Hosting and Server</p>
          </div>
          <div>
            <p className="info-txt rtl c-white-70">
              دنبال یک هاست مناسب با منابع اختصاصی هستید ؟ میتوانید روی ما حساب کنید ! هاست های اختصاصی با قیمت مناسب    برخی
              از ویژگی های کلیدی هاستینگ شرکت عماد :
            </p>
          </div>
          <div>
            <p className="info-list">
              امنیت بالا{" "}
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
          <div>
            <p className="info-list">
              پشتیبانی تخصصی{" "}
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
          <div>
            <p className="info-list">
              وضعیت نرم افزاری و سخت افزاری مناسب سرور ها{" "}
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
        </div>
        <div className="xs-mb-60 l-mb-0 d-flex justify-content-center align-items-center">
          <img className="info-two-img" src={infoTwo} />
        </div>
        <div
          onMouseDown={() => {
            plusSlidesi(-1);
          }}
          className="lth-open"
        ></div>
        <div
          onMouseDown={() => {
            plusSlidesi(1);
          }}
          className="rth-open"
        ></div>
      </div>
      <div className="mySlidesi fadei l-d-flex flex-column flex-lg-row justify-content-end justify-content-lg-between align-items-lg-center">
        <div className="xs-mb-60 l-mb-0 d-flex justify-content-center align-items-center">
          <img className="info-three-img" src={infoThree} />
        </div>
        <div className="xs-mr-30 m-mr-0 d-flex flex-column justify-content-center align-items-end h-100">
          <div className="position-relative">
            <p className="info-header bold">ارز دیجیتال</p>
            <p className="orange-box position-absolute z-n-1"></p>
          </div>
          <div>
            <p className="info-en">Digital Currency</p>
          </div>
          <div>
            <p className="info-txt rtl c-white-70 text-align-justify">
              در صورتی که به بازار کرایپوکارنسی و یا همان رمز ارز ها علاقه دارید
              و قصد سرمایه گذاری در این بازار را دارید میتوانید با استفاده از
              خدمات امور ارز دیجیتال ما در بازار ارز های دیجیتال سرمایه گذاری
              کنید و هر زمان که میخواهید خرید و فروش ارز ها یدیجیتال انجام دهید.
            </p>
          </div>
          <div>
            <p className="info-list">
              خرید و فروش آنی
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
          <div>
            <p className="info-list">
              کارمزد منصفانه و رقابتی
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
          <div>
            <p className="info-list">
              ارتباط مستقیم هنگام خرید و فروش
              <span>
                <img src={tick} />
              </span>
            </p>
          </div>
        </div>
        <div
          onMouseDown={() => {
            plusSlidesi(-1);
          }}
          className="lth-open"
        ></div>
        <div
          onMouseDown={() => {
            plusSlidesi(1);
          }}
          className="rth-open"
        ></div>
      </div>
    </div>
  );
}

export default Information;
