import "./ScrollOne.css";
import arrowleft from "../../Images/arrowleft.png";
import alImage from "../../Images/al.png";
import arImage from "../../Images/ar.png";
import { useState } from "react";
import { useEffect } from "react";

function ScrollOne(props) {
  const [range, setRange] = useState(0);
  const [max, setMax] = useState(0);

  const changer = (inner) => {
    setRange(inner);
  };

  const scrolled = () => {
    let scrollpos = document.getElementById(props.id).scrollLeft;
    setRange(scrollpos);
  };

  const arrow = (point) => {
    if (point < 0) {
      if (range + 100 <= max) {
        setRange(range + 100);
      }
    }
    if (point > 0) {
      if (range - 100 >= 0) {
        setRange(range - 100);
      }
    }
  };

  useEffect(() => {
    document.getElementById(props.id).scrollLeft = range;
    let childs = document
      .getElementById(props.id)
      .getElementsByClassName("card-scoroll").length;
    setMax(childs * 100);
  }, [range]);

  return (
    <>
      <div className="container d-flex flex-row-reverse justify-content-between align-items-center mb-50 px-20">
        <div className="position-relative">
          <p className="rtl c-white bold scroll-one-header-fa">
            جدید ترین نمونه کار ها
          </p>
          <p className="orange-box position-absolute scroll-orange-box z-n-1"></p>
          <p className="rtl c-white-70 scroll-header-en">LATEST WORKS</p>
        </div>
        <div className="d-flex justify-content-start align-items-center">
          <div>
            <img
              onClick={() => {
                arrow(-1);
              }}
              className="pointer range-arrow"
              src={alImage}
              alt="Previous"
            />
          </div>
          <div className="slidecontainer mx-20">
            <input
              onChange={(e) => {
                changer(parseInt(e.target.value));
              }}
              type="range"
              min="1"
              max={max}
              value={range}
              className="slider"
              id="myRange"
            />
          </div>
          <div>
            <img
              onClick={() => {
                arrow(1);
              }}
              className="pointer range-arrow"
              src={arImage}
              alt="Next"
            />
          </div>
        </div>
      </div>
      <div
        id={props.id}
        onScroll={scrolled}
        className="container frol scroll-one-container d-flex justify-content-center"
      >
        <div className="ml-im-80">
          <div className="card-scoroll d-flex flex-column">
            <div className="img-bg d-flex justify-content-center align-items-start">
              <iframe
                src="#"
                title="example1"
                style={{ width: '100%', height: '100%', border: 'none', borderRadius: '3px' }}
              ></iframe>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center txt-card">
              <div>
                <img className="arrow" src={arrowleft} alt="Arrow Left" />
              </div>
              <div className="mtn-footer d-flex flex-column justify-content-center align-items-end">
                {/* <p className="c-white">سایت شخصی سحر خرسندی</p> 
                <p className="c-white-80">saharkhorsandi.com</p>  */}
              </div>
            </div>
          </div>
        </div>
        
        <div className="ml-im-80">
          <div className="card-scoroll d-flex flex-column">
            <div className="img-bg d-flex justify-content-center align-items-start">
              <iframe
                src="https://saharkhorsandi.com/#sec_1"
                title="example1"
                style={{ width: '100%', height: '100%', border: 'none', borderRadius: '3px' }}
              ></iframe>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center txt-card">
              <div>
                <img className="arrow" src={arrowleft} alt="Arrow Left" />
              </div>
              <div className="mtn-footer d-flex flex-column justify-content-center align-items-end">
                <p className="c-white">سایت شخصی سحر خرسندی</p> {/* تغییر نام سایت */}
                <p className="c-white-80">saharkhorsandi.com</p> {/* تغییر توضیحات */}
              </div>
            </div>
          </div>
        </div>
        <div className="ml-im-80">
          <div className="card-scoroll d-flex flex-column">
            <div className="img-bg d-flex justify-content-center align-items-start">
              <iframe
                src="https://sakhtemantehran.ir/"
                title="example2"
                style={{ width: '100%', height: '100%', border: 'none', borderRadius: '3px' }}
              ></iframe>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center txt-card">
              <div>
                <img className="arrow" src={arrowleft} alt="Arrow Left" />
              </div>
              <div className="mtn-footer d-flex flex-column justify-content-center align-items-end">
                <p className="c-white">سایت ساختمان تهران</p> 
                <p className="c-white-80">sakhtemantehran.ir</p> 
              </div>
            </div>
          </div>
        </div>
        <div className="ml-im-80">
          <div className="card-scoroll d-flex flex-column">
            <div className="img-bg d-flex justify-content-center align-items-start">
              <iframe
                src="https://powertelecomtv.com/"
                title="example3"
                style={{ width: '100%', height: '100%', border: 'none', borderRadius: '3px' }}
              ></iframe>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center txt-card">
              <div>
                <img className="arrow" src={arrowleft} alt="Arrow Left" />
              </div>
              <div className="mtn-footer d-flex flex-column justify-content-center align-items-end">
                <p className="c-white">سایت ماهواره پاورتلکام</p>
                <p className="c-white-80">powertelecomtv.com</p> 
              </div>
            </div>
          </div>
        </div>
        <div className="ml-im-80">
          <div className="card-scoroll d-flex flex-column">
            <div className="img-bg d-flex justify-content-center align-items-start">
              <iframe
                src="https://www.alimorshedi.com/"
                title="example4"
                style={{ width: '100%', height: '100%', border: 'none' , borderRadius: '3px' }}
              ></iframe>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center txt-card">
              <div>
                <img className="arrow" src={arrowleft} alt="Arrow Left" />
              </div>
              <div className="mtn-footer d-flex flex-column justify-content-center align-items-end">
                <p className="c-white">وب سایت شخصی علی مرشدی</p>
                <p className="c-white-80">alimorshedi.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-im-80">
          <div className="card-scoroll d-flex flex-column">
            <div className="img-bg d-flex justify-content-center align-items-start">
              <iframe
                src="https://4utv.tv"
                title="4UTv"
                style={{ width: '100%', height: '100%', border: 'none', borderRadius: '3px' }}
              ></iframe>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center txt-card">
              <div>
                <img className="arrow" src={arrowleft} alt="Arrow Left" />
              </div>
              <div className="mtn-footer d-flex flex-column justify-content-center align-items-end">
                <p className="c-white">شبکه تلوزیونی فوریو</p> 
                <p className="c-white-80">4utv.tv</p> 
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default ScrollOne;
