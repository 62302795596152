import "./Contact.css";
import Form2 from "../../Components/Form2/Form2.js";
import ContactCard from "../../Components/ContactCard/ContactCard";

function Contact() {
  return (
    <div className="container d-flex flex-column-reverse flex-lg-row justify-content-center justify-content-lg-between">
      <div className="flex-fill">
        <Form2 />
      </div>
      <div className="d-flex justify-content-between contact-cards">
        <ContactCard header="آدرس ایمیل" text="contact@emad.team" icon="message" />
        <div className="contact-ml"></div>
          <ContactCard header="تماس با ما" text="021 - 91 03 16 93" icon="calling"/>
      </div>
    </div>
  );
}

export default Contact;
