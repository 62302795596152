import './CommentsForm.css';
import person from '../../Images/person.png';
import heart from '../../Images/heart.png';
import MainBtn from '../../Components/MainBtn/MainBtn';
import MainBtnOpen from '../../Components/MainBtnOpen/MainBtnOpen';
import { useState } from 'react';

function CommentsForm() {
    const [likeOne, setLikeOne ] = useState(13);
    const [isLikeOne, setIsLikeOne] = useState(false);

    const likedOne = () => {
        if(!isLikeOne){
            setLikeOne(likeOne => ++likeOne);
            setIsLikeOne(true);
        }else{
            setLikeOne(likeOne => --likeOne);
            setIsLikeOne(false);
        }
    }

    const [likeTwo, setLikeTwo ] = useState(13);
    const [isLikeTwo, setIsLikeTwo] = useState(false);

    const likedTwo = () => {
        if(!isLikeTwo){
            setLikeTwo(likeTwo => ++likeTwo);
            setIsLikeTwo(true);
        }else{
            setLikeTwo(likeTwo => --likeTwo);
            setIsLikeTwo(false);
        }
    }

    return (
    <>
        <div className="pn position-relative d-flex flex-column">
            <p className="lh-3 f-20 c-white rtl">دیدگاه کاربران</p>
            <p className="f-11 c-white-70 rtl">COMMENTS</p>
            <p className="orange-box zz position-absolute z-n-1"></p>
        </div>
        <div className='comments-form-container w-100 d-flex flex-column'>
            <div className='d-flex flex-column flex-lg-row-reverse justify-content-between'>
                <div className='w-100 xs-ml-0 ml-19 xs-mb-20 l-mb-0'>
                    <input className="comment-input w-100 rtl" type="text" placeholder='نام و نام خانوادگی'></input>
                </div>
                <div className='w-100'>
                    <input className="comment-input w-100 rtl" type="text" placeholder='ایمیل یا شماره تلفن'></input>
                </div>
            </div>
            <div className='w-100 mt-19 mb-21'>
                <textarea className='w-100 comment-input comment-input-textarea rtl' placeholder='نظر خودرا وارد کنید'></textarea>
            </div>
            <div className='mb-21'>
                <div className='xs-d-none l-d-block'>
                    <MainBtn text="ارسال پیام"/>
                </div>
                <div className='xs-d-block l-d-none'>
                    <MainBtnOpen text="ارسال پیام"/>
                </div>
            </div>
            <div className='w-100'>
                <div className='comments-container d-flex flex-column'>
                    <div className='d-flex flex-column-reverse flex-lg-row align-items-center comment-one'>
                        <div className='w-100 flex-fill person-comment-text-container'>
                            <p className='c-white-70 rtl person-comment-text'>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد</p>                            
                        </div>
                        <div className='d-flex flex-row-reverse justify-content-start align-items-center self-comment xs-mt-20 xs-mb-20'>
                            <div className='comment-profile-container h-100 d-flex flex-row justify-content-center align-items-center'>
                                <img className="comment-profile" src={person} />
                            </div>
                            <div className='d-flex flex-column comment-person-data'>
                                <div>
                                    <p className='c-white bold comment-person-data-name rtl'>حمیدرضا ساداتی</p>
                                </div>
                                <div>
                                    <p className='c-white-70 comment-person-data-time rtl'>در تاریخ 11 مرداد 1403</p>
                                </div>
                                <div>
                                    <p className='c-white comment-person-data-like rtl'><img className="pointer" onClick={likedOne} src={heart} /><span>{likeOne}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column-reverse flex-lg-row align-items-center self-comment'>
                        <div className='w-100 person-comment-text-container'>
                            <p className='c-white-70 rtl person-comment-text'>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد</p>
                        </div>
                        <div className='d-flex flex-row-reverse justify-content-start align-items-center xs-mt-20 xs-mb-20'>
                            <div className='comment-profile-container h-100 d-flex flex-row justify-content-center align-items-center'>
                                <img className="comment-profile" src={person} />
                            </div>
                            <div className='d-flex flex-column comment-person-data'>
                                <div>
                                    <p className='c-white bold comment-person-data-name rtl'>هاشم آزادی</p>
                                </div>
                                <div>
                                    <p className='c-white-70 comment-person-data-time rtl'>در تاریخ 11 مرداد 1399</p>
                                </div>
                                <div>
                                    <p className='c-white comment-person-data-like rtl'><img className="pointer" onClick={likedTwo} src={heart} /><span>{likeTwo}</span></p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default CommentsForm;

