import './PageFour.css';
import Service from '../../sections/Service/Service.js';
import ServiceLine from '../../Components/ServiceLine/ServiceLine.js';
import Improve from '../../sections/Improve/Improve';
import MainForm from '../../sections/MainForm/MainForm';
import Footer from '../../sections/Footer/Footer';
import Header from '../../sections/Header/Header';
import {useState, useEffect} from 'react';
import HeaderService from '../../sections/HeaderService/HeaderService';
import { Helmet } from 'react-helmet';

function PageFour(){
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[]);
    
    return (
        <div className='page-four-container'>
            <Helmet>
                <title>خدمات ما - تیم عماد</title>
                <meta name="description" content="صفحه خدمات ما تیم عماد" />
                <meta name="keywords" content="تیم عماد, خدمات, سرویس‌ها" />
            </Helmet>
            <div className='xs-d-none l-d-block'>
                <Header />
            </div>
            <div className='xs-d-block l-d-none'>
                <HeaderService />
            </div>
            
            <div className='space-4-1'></div>
            <div className='xs-d-none l-d-block'>
                <ServiceLine />
            </div>
            <div className='space-4-2'></div>
            <div className='container'>
                <Service />
            </div>
            <div className="space-4-3"></div>
            <div className='xs-d-none l-d-block'>
                <Improve />
                <div className='space-4-4'></div>
                <div className='container'>
                    <MainForm />
                </div>
                <div className='space-4-5'></div>
                <Footer />
            </div>
        </div>
    );
}

export default PageFour;
