import DropQuestion from "../../Components/DropQuestion/DropQuestion";
import "./Question.css";

function Question() {
  return (
    <div>
      <div className="position-relative mb-40">
        <p className="rtl c-white bold scroll-one-header-fa">سوالات متداول</p>
        <p className="orange-box position-absolute scroll-orange-box z-n-1"></p>
        <p className="rtl c-white-70 scroll-header-en">FAQS</p>
      </div>
      <DropQuestion
        id="quest-1"
        title="بعد از خرید ، چقد زمان میبره سفارش من اجرا شود‌‌؟"
        answer="بلافاصله بعد از پرداخت به صورت اتوماتیک سفارش شما اجرا و اضافه خواهد شد."
      />
      <div className="mb-30"></div>
      <DropQuestion
        id="quest-2"
        title="آیا برای خرید نیاز به پسورد هست؟"
        answer="خیر برای هیچ کدام از سرویس ها نیازی به رمز عبور نداریم."
      />
      <div className="mb-30"></div>
      <DropQuestion
        id="quest-3"
        title="چرا از ما خرید کنید؟"
        answer="چون در سایت ما تمام خدمات به صورت اتوماسیون انجام می شود و شما نیاز به صبر کردن برای تایید و انجام سفارشتان ندارید."
      />
      <div className="mb-30"></div>
      <DropQuestion
        id="quest-4"
        title="آیا امکان تفکیک کردن جنسیت در سفارشات وجود دارد؟"
        answer="سرویس ها و خدمات متفاوت هستند. برای بررسی دقیق تر لطفا قبل از سفارش به صورت آنلاین از ما بپرسید."
      />
    </div>
  );
}

export default Question;
