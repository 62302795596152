import "./Instagram.css";
import DropInsta from "../../Components/DropInsta/DropInsta";
import Question from "../../sections/Question/Question";
import ScrollTwo from "../../sections/ScrollTwo/ScrollTwo";
import quest from "../../Images/quest.png";
import Improve from "../../sections/Improve/Improve.js";
import MainForm from "../../sections/MainForm/MainForm.js";
import Footer from "../../sections/Footer/Footer.js";
import BannerInsta from "../../sections/BannerInsta/BannerInsta";
import Header from "../../sections/Header/Header.js";
import { useState, useEffect } from "react";
import InstaPU from "../../Components/InstaPU/InstaPU";
import HeaderInsta from "../../sections/HeaderInsta/HeaderInsta";
import FooterMob from "../../sections/FooterMob/FooterMob";
import { Link } from "react-router-dom";
import DropInsta2 from "../../Components/DropInsta2/DropInsta2";
import { Helmet } from "react-helmet";

function Instagram() {
  const [openerPUI, setOpenerPUI] = useState(false);

  const openPUI = () => {
    if (!openerPUI) {
      setOpenerPUI(true);
    } else {
      setOpenerPUI(false);
    }
  };

  useEffect(() => {
    if (openerPUI) {
      document.getElementById("pul").classList.add("pu-open");
      document.getElementById("pul").classList.remove("pu-close");
    } else {
      document.getElementById("pul").classList.add("pu-close");
      document.getElementById("pul").classList.remove("pu-open");
    }
  }, [openerPUI]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <title>تیم عماد | خدمات شبکه های اجتماعی</title>
        <meta name="description" content="صفحه خدمات شبکه های اجتماعی تیم عماد. ارائه دهنده خدمات خرید لایک و فالوئر، مشاوره رایگان و پشتیبانی حرفه‌ای." />
        <meta name="keywords" content="تیم عماد, خدمات شبکه های احتماعی, خرید لایک, خرید فالوئر, مشاوره رایگان, پشتیبانی حرفه‌ای" />
      </Helmet>
      <div className="instagram-container position-relative">
        <div id="pul" className="xs-d-none l-d-block">
          <InstaPU onChange={openPUI} />
        </div>
        <div className="xs-d-none l-d-block">
          <Header />
        </div>
        <div className="xs-d-block l-d-none">
          <HeaderInsta />
        </div>
        <div className="insta-space-1"></div>
        <div className="xs-d-none l-d-block">
          <BannerInsta />
        </div>
        <div className="xs-mb-0 l-mb-150"></div>
        <div id="insta" className="xs-d-none l-d-block container mb-77">
          <div className="detail d-flex justify-content-center w-100">
            <div className="detail-item detail-border">
              <p className="c-main bold rtl f-30">900 ام</p>
              <p className="c-white-70 f-14">لایک خریداری شده</p>
            </div>
            <div className="detail-item detail-border">
              <p className="c-main bold rtl f-30">50 ام</p>
              <p className="c-white-70 f-14">فالوئر تحویل داده شده</p>
            </div>
            <div className="detail-item detail-border">
              <p className="c-main bold rtl f-30">7 کا</p>
              <p className="c-white-70 f-14">خرید موفق</p>
            </div>
            <div className="detail-item">
              <p className="c-main bold rtl f-30">3</p>
              <p className="c-white-70 f-14">سال تجربه حرفه ای</p>
            </div>
          </div>
        </div>
        <div className="container mb-30">
          <div className="flex-fill xs-d-block lg-d-none mobile-drop-container w-100">
            <div className="flex-column flex-lg-row justify-content-between align-items-center">
              <DropInsta2 />
              <DropInsta2 />
              <DropInsta2 />
              <DropInsta2 />
            </div>
          </div>

          <div className="xs-d-none l-d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <DropInsta onChange={openPUI} id="el1" />
            <DropInsta onChange={openPUI} id="el2" />
            <DropInsta onChange={openPUI} id="el3" />
            <DropInsta onChange={openPUI} id="el4" />
          </div>
          <div className="xs-d-none l-d-flex justify-content-between">
            <DropInsta onChange={openPUI} id="el5" />
            <DropInsta onChange={openPUI} id="el6" />
            <DropInsta onChange={openPUI} id="el7" />
            <DropInsta onChange={openPUI} id="el8" />
          </div>
          <div className="xs-d-none l-d-flex justify-content-between">
            <DropInsta onChange={openPUI} id="el9" />
            <DropInsta onChange={openPUI} id="el10" />
            <DropInsta onChange={openPUI} id="el11" />
            <DropInsta onChange={openPUI} id="el12" />
          </div>
          <div className="xs-d-none l-d-flex justify-content-between">
            <DropInsta onChange={openPUI} id="el13" />
            <DropInsta onChange={openPUI} id="el14" />
            <DropInsta onChange={openPUI} id="el15" />
            <DropInsta onChange={openPUI} id="el16" />
          </div>
        </div>
        <div className="h-100 xs-d-none container mb-150 l-d-flex justify-content-between align-items-center">
          <div className="quest-container mr-60">
            <img src={quest} />
          </div>
          <div>
            <Question />
          </div>
        </div>
        <div className="xs-d-none l-d-block xs-mb-0 l-mb-150">
          <ScrollTwo id="instascroll" />
        </div>
        <div className="xs-d-none l-d-block">
          <Improve />
          <div className="insta-space-2"></div>
          <div className="container">
            <MainForm />
          </div>
          <div className="insta-space-3"></div>
          <div className="xs-d-none l-d-block">
            <Footer />
          </div>
        </div>
        <div className="xs-d-block l-d-none footer-mobile-insta-container">
          <FooterMob />
        </div>
      </div>
    </>
  );
}

export default Instagram;
