import './GraphicView.css';
import { Link } from 'react-router-dom';

function GraphicView() {
    return (
        <div className="view-nav-main w-100 d-flex">
            <p className="c-white d-flex justify-content-center align-items-center w-100">
                <Link className='link1 pointer' to="/contact">
                    <span className="pointer view-nav-main-link mr-7">مشاوره رایگان</span>
                </Link>
                <span className="view-nav-main-slash mr-7">-
                </span>  در این ضمینه نیاز به مشاوره دارید ؟ در خدمت شما هستیم
            </p>
        </div>
    );
}

export default GraphicView;