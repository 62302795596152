import AboutUsLine from '../../Components/AboutUsLine/AboutUsLine';
import AboutUs from '../../sections/AboutUs/AboutUs';
import Header from '../../sections/Header/Header';
import Improve from '../../sections/Improve/Improve';
import MainForm from '../../sections/MainForm/MainForm';
import Footer from '../../sections/Footer/Footer';
import './PageThree.css';
import {useState, useEffect} from 'react';
import HeaderAbout from '../../sections/HeaderAbout/HeaderAbout';
import FooterMob from '../../sections/FooterMob/FooterMob';
import { Helmet } from 'react-helmet';

function PageThree() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[]);
    
    return (
        <div className='page-three-container'>
            <Helmet>
                <title>درباره ما - تیم عماد</title>
                <meta name="description" content="صفحه درباره ما تیم عماد" />
                <meta name="keywords" content="تیم عماد, درباره ما, معرفی" />
            </Helmet>
            <div className='xs-d-none l-d-block'>
                <Header />
            </div>
            <div className='xs-d-block l-d-none'>
                <HeaderAbout />
            </div>
            <div className='space-3-1'></div>
            <div className='container-fluid xs-d-none l-d-block xs-mb-0 l-mb-60'>
                <AboutUsLine />
            </div>
            <div className='container space-3-2'>
                <AboutUs />
            </div>
            <div className='xs-d-none l-d-block'>
                <Improve />
                <div className='space-3-3'></div>
                <div className='container'>
                    <MainForm />
                </div>
                <div className='space-3-4'></div>
                <Footer />
            </div>
            <div className='xs-d-block l-d-none xs-mt-30'>
                <FooterMob />
            </div>
        </div>
    );
}

export default PageThree;
