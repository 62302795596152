import './PageFive.css';
import FAQS from '../../sections/FAQS/FAQS.js';
import FAQSLine from '../../Components/FAQSLine/FAQSLine.js';
import Header from '../../sections/Header/Header';
import Improve from '../../sections/Improve/Improve';
import MainForm from '../../sections/MainForm/MainForm';
import Footer from '../../sections/Footer/Footer';
import { useState, useEffect } from 'react';
import HeaderFaqs from '../../sections/HeaderFaqs/HeaderFaqs';
import FooterMob from '../../sections/FooterMob/FooterMob';
import { Helmet } from 'react-helmet';

function PageFive() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div className='page-five-container'>
            <Helmet>
                <title>تیم عماد | سوالات متداول</title>
                <meta name="description" content="سوالات متداول تیم عماد" />
                <meta name="keywords" content="تیم عماد, سوالات متداول, FAQ" />
            </Helmet>
            <div className='xs-d-none l-d-block'>
                <Header />
            </div>
            <div className='xs-d-block l-d-none'>
                <HeaderFaqs />
            </div>

            <div className='space-5-1'></div>
            <div className='xs-d-none l-d-block'>
                <FAQSLine />
            </div>
            <div className='space-5-1'></div>
            <div className='container'>
                <FAQS />
            </div>
            <div className='space-5-2'></div>
            <div className='xs-d-none l-d-block'>
                <Improve />
                <div className='space-5-3'></div>
                <div className='container'>
                    <MainForm />
                </div>
                <div className='space-5-4'></div>
                <Footer />
            </div>
            <div className='w-fb xs-d-block l-d-none'>
                <FooterMob />
            </div>
        </div>
    );
}

export default PageFive;
