import './AdminBtn.css';
import admin from '../../Images/admin.png';

function AdminBtn() {
    return (
        <div className='adminbtn-container'>
            <button className='pointer bg-main d-flex justify-content-center align-items-center'>
                <span className='adminbtn-text c-white'>حساب کاربری</span>
                <span><img className='adminbtn-icon' src={admin} alt="Admin icon"/></span>
            </button>
        </div>
    );
}

export default AdminBtn;