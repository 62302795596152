import './Factors.css';
import factors from '../../Images/factors.png';

function Factors() {
    return (
    <> 
        <div className="xs-d-none container l-d-flex flex-column">
            <div className="w-100 d-flex flex-column align-items-center factors-header">
                <div className="d-flex justify-content-center align-items-center">
                    <img className="factors-icon" src={factors} />
                </div>
                <div>
                    <p className="c-white bold factors-header-fa">آمار و ارقام</p>
                </div>
                <div>
                    <p className="c-white-70 factors-header-en">Facts and Figures</p>
                </div>
            </div>
            <div className="factors-content d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-between">
                    <div className="d-flex flex-column align-items-center xs-mb-40-vw l-mb-0">
                        <div className="factors-circle-container">
                            <p className="c-white">% 99</p>
                        </div>
                        <div>
                            <p className="c-white bold">آپتایم سرویس ها</p>
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <div className="factors-circle-container">
                            <p className="c-white">% 93</p>
                        </div>
                        <div>
                            <p className="c-white bold">رضایت مشتریان از پشتیبانی</p>
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center xs-mb-40-vw l-mb-0">
                        <div className="factors-circle-container">
                            <p className="c-white">% 96</p>
                        </div>
                        <div>
                            <p className="c-white bold">درصد رضایت مشتریان </p>
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <div className="factors-circle-container center">
                            <p className="c-white">% 99</p>
                        </div>
                        <div>
                            <p className="c-white bold">تحویل به موقع</p>
                        </div>
                    </div>
            </div>
        </div>

        <div className="l-d-none container xs-d-flex flex-column">
            <div className="w-100 d-flex flex-column align-items-center factors-header">
                <div className="d-flex justify-content-center align-items-center">
                    <img className="factors-icon" src={factors} />
                </div>
                <div>
                    <p className="c-white bold factors-header-fa">آمار و ارقام</p>
                </div>
                <div>
                    <p className="c-white-70 factors-header-en">Facts and Figures</p>
                </div>
            </div>
            <div className="factors-content d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-between">
                <div className='d-flex justify-content-between  flex-fill'>
                    <div className="d-flex flex-column align-items-center xs-mb-40-vw l-mb-0">
                        <div className="factors-circle-container">
                            <p className="c-white">% 99</p>
                        </div>
                        <div>
                            <p className="c-white bold">آپتایم سرویس ها</p>
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <div className="factors-circle-container">
                            <p className="c-white">% 93</p>
                        </div>
                        <div>
                            <p className="c-white bold">رضایت مشتریان از پشتیبانی</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-between  flex-fill'>
                    <div className="d-flex flex-column align-items-center xs-mb-40-vw l-mb-0">
                        <div className="factors-circle-container">
                            <p className="c-white">% 96</p>
                        </div>
                        <div>
                            <p className="c-white bold">درصد رضایت مشتری</p>
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <div className="factors-circle-container center">
                            <p className="c-white">% 99</p>
                        </div>
                        <div>
                            <p className="c-white bold">تحویل به موقع</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default Factors;