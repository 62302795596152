import './ServiceLine.css';
import service from '../../Images/service.png';
import line from '../../Images/line.png';

function ContactLine(){
    return (
        <div className='contact-line d-flex justify-content-center align-items-center' style={{backgroundImage: 'url('+line+')'}}>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column align-items-center'>
                    <div>
                        <p className='c-white bold fa'>خدمات ما</p>
                    </div>
                    <div>
                        <p className='c-white en'>SERVICES</p>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <img src={service} />
                </div>
            </div>
        </div>
    );
}

export default ContactLine;