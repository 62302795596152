import './KCard.css';
import telegram from '../../Images/telegram-main.png';
import instagram from '../../Images/instagram-main.png';

function KCard(props){
    return (
        <>
            <div className='xs-d-none l-d-flex k-card-container justify-content-between align-items-center'>
                <div className='h-100 d-flex align-items-start'>
                    <p className='c-white-70 bold rtl'>emaddesign</p>
                </div>
                <div className='h-100'>
                    <p className='c-white bold rtl k-card-title'>
                        {props.title}
                    </p>
                    <p className='c-white bold rtl k-card-number'>
                        {props.number}K
                    </p>
                </div>
                <div className='h-100'>
                    <div className="k-card-icon d-flex justify-content-center align-items-center">
                        <img  src={(props.icon === 'instagram')? instagram : telegram} />
                    </div>
                </div>
            </div>

            <div className='xs-d-flex l-d-none flex-column-reverse justify-content-between align-items-center k-card-container-s'>
                <div className='h-100 d-flex align-items-center'>
                    <p className='c-white-70 bold f-14 rtl'>emaddesign</p>
                </div>
                <div className='h-100 d-flex flex-column align-items-center'>
                    <p className='c-white bold rtl k-card-title f-20'>
                        {props.title}
                    </p>
                    <p className='c-white bold rtl k-card-number f-20'>
                        {props.number}K
                    </p>
                </div>
                <div className='h-100'>
                    <div className="k-card-icon-s d-flex justify-content-center align-items-center">
                        <img  src={(props.icon === 'instagram')? instagram : telegram} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default KCard;